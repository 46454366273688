<template>
  <n-cascader :value="selection" @update:value="onUpdateSelection" :options="selections" placeholder="请选择" check-strategy="child"> </n-cascader>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { CascaderOption } from "naive-ui"
import { getAddressByCode, getAddressesByParentCode } from "@/common/address/AddressUtil"

import message from "@/util/message"

const props = defineProps<{
  townCode: number
}>()
const emits = defineEmits<{
  select: [province: number, city: number, county: number, town: number]
}>()

const selection = computed(() => {
  if (props.townCode === 0) {
    return ""
  } else {
    return props.townCode + ""
  }
})

const selections = computed(() => {
  const result = getSelectionsForCascader(450000000000)
  return result
})

const getSelectionsForCascader = (parentCode: number): Array<CascaderOption> => {
  const addresses = getAddressesByParentCode(parentCode)
  const result = [] as Array<CascaderOption>
  for (const address of addresses) {
    const child = getSelectionForCascader(address.code)
    if (!child) {
      continue
    }
    result.push(child)
  }
  return result
}

const getSelectionForCascader = (code: number): CascaderOption | undefined => {
  const address = getAddressByCode(code)
  if (!address) {
    return undefined
  }
  const childrenAddr = getAddressesByParentCode(address.code)
  let children = [] as any
  for (const childAddr of childrenAddr) {
    const child = getSelectionForCascader(childAddr.code)
    if (child) {
      children.push(child)
    }
  }
  if (children.length == 0) {
    children = undefined
  }
  return {
    label: address.name,
    value: address.code + "",
    children: children,
  }
}

const onUpdateSelection = (selection: string) => {
  const townCode = Number.parseInt(selection)
  const town = getAddressByCode(townCode)
  if (!town) {
    message.warning("找不到该地址")
    return
  }
  const county = getAddressByCode(town.parentCode)
  if (!county) {
    message.warning("找不到该地址")
    return
  }
  const city = getAddressByCode(county.parentCode)
  if (!city) {
    message.warning("找不到该地址")
    return
  }
  emits("select", 450000000000, city.code, county.code, town.code)
}
</script>
<style scoped></style>
