<template>
  <div class="flex-row-nowrap-start-start-center">
    <div style="flex-shrink: 0; margin-right: 8px">{{ title }}</div>
    <div v-if="find" class="flex-row-nowrap-start-start-center">
      <n-popover ref="findPanel" trigger="click" placement="bottom" :delay="0" @update:show="onFindToggle">
        <template #trigger>
          <div class="flex-row-nowrap-center-center-center">
            <ec-icon name="common/find" :size="15" :highlight="findActive" color="#505050" highlightColor="#2593f2" clickable></ec-icon>
          </div>
        </template>
        <find-panel :keyword="state.find.keyword" @update:keyword="onUpdateKeyword" @find="onFind"></find-panel>
      </n-popover>
      <div v-if="showFindKeyword" class="flex-row-wrap-start-start-center" style="margin-left: 8px">
        <div>"{{ state.find.keyword }}"</div>
        <ec-icon style="margin-left: 4px" name="common/clear" :size="15" color="#aaaaaa" clickable @click="onClearFind"></ec-icon>
      </div>
    </div>
    <div v-if="filter" class="flex-row-nowrap-start-start-center">
      <n-popover ref="filterPanel" trigger="click" placement="bottom" :delay="0" @update:show="onFilterToggle">
        <template #trigger>
          <div class="flex-row-nowrap-center-center-center">
            <ec-icon name="common/filter" :size="15" :highlight="state.filter.selectedIds.length > 0" color="#505050" highlightColor="#2593f2" clickable></ec-icon>
          </div>
        </template>
        <filter-panel :selectedIds="state.filter.selectedIds" @update:selectedIds="onUpdateFilterSelectedIds" :options="filterOptions" :multiple="filterMultiple" @filter="onFilter"></filter-panel>
      </n-popover>
      <span v-if="false" style="vertical-align: middle">
        {{ filterHint }}
        <span style="line-height: 24px">
          <ec-icon style="position: relative; top: 7px" name="common/clear" :size="24" color="#aaaaaa" clickable @click="onClearFilter"> </ec-icon>
        </span>
      </span>
    </div>
    <div class="flex-grow-1"></div>
  </div>
</template>
<script lang="ts" setup>
import { computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref, toRef, useAttrs, useSlots, watch, h } from "vue"
import FilterPanel from "@/components/column/FilterPanel.vue"
import FindPanel from "@/components/column/FindPanel.vue"
import { Option } from "@/components/model/Option"
import { NPopover } from "naive-ui"

const props = withDefaults(
  defineProps<{
    title: string
    filter?: boolean
    filterMultiple?: boolean
    filterOptions?: Array<Option>
    filterSelectedIds?: Array<string>
    filterSelectedTexts?: Array<string>
    find?: boolean
    findKeyword?: string
    findActive?: boolean
  }>(),
  {
    filter: false,
    find: false,
  }
)
const emits = defineEmits<{
  (e: "filter", selectedIds: Array<string>, selectedTexts: Array<string>): void
  (e: "find", keyword: string): void
}>()

const filterPanel = ref<InstanceType<typeof NPopover> | null>(null)
const findPanel = ref<InstanceType<typeof NPopover> | null>(null)

const state = reactive({
  filter: {
    selectedIds: props.filterSelectedIds ?? [],
    changed: false,
    selectedTexts: props.filterSelectedTexts ?? [],
  },
  find: {
    changed: false,
    keyword: props.findKeyword ?? "",
    show: false,
  },
})

const onFilter = () => {
  filterPanel.value?.setShow(false)
  emits("filter", state.filter.selectedIds, state.filter.selectedTexts)
}

const onFilterToggle = (show: boolean) => {
  if (show) {
    state.filter.changed = false
  } else if (state.filter.changed) {
    onFilter()
  }
}

const onUpdateFilterSelectedIds = (selectedIds: Array<string>, selectedTexts: Array<string>) => {
  state.filter.selectedIds = selectedIds
  state.filter.selectedTexts = selectedTexts
  state.filter.changed = true
}

const onClearFilter = () => {
  state.filter.selectedIds = []
  state.filter.selectedTexts = []
  onFilter()
}

const filterHint = computed(() => {
  if (state.filter.selectedTexts.length <= 0) {
    return ""
  }
  let hint = ""
  for (let i = 0; i < state.filter.selectedTexts.length; i++) {
    if (i < 3) {
      hint = hint + `${state.filter.selectedTexts[i]}、`
    }
  }
  hint = hint.slice(0, hint.length - 1)
  if (state.filter.selectedTexts.length > 3) {
    hint = hint + "等"
  }
  return hint
})

const onFind = () => {
  findPanel.value?.setShow(false)
  let keyword = JSON.parse(JSON.stringify(state.find)).keyword
  emits("find", keyword)
}

const onFindToggle = (show: boolean) => {
  if (show) {
    state.find.changed = false
  } else if (state.find.changed) {
    onFind()
  }
}

const onUpdateKeyword = (keyword: string) => {
  state.find.keyword = keyword
  state.find.changed = true
}

const onClearFind = () => {
  state.find.keyword = ""
  onFind()
}

const showFindKeyword = computed(() => {
  return props.findActive
})
</script>
<style scoped></style>
