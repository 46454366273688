<template>
  <div :class="buttonClass" :style="buttonStyle" @mouseover="state.hover = true" @mouseleave="state.hover = false">
    <ec-icon v-if="hasIcon" :name="icon" :size="iconSize" :color="color" :highlightColor="hilightColor" :highlight="state.hover" :disabled="disabled"></ec-icon>
    <div v-if="hasIcon && !iconButton" style="width: 4px; height: 1px"></div>
    <n-spin style="margin-right: 8px" :size="16" v-if="loading" :stroke="loadingColor" />
    <div :style="buttonTextStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { reactive, computed } from "vue"

const props = withDefaults(
  defineProps<{
    iconButton?: boolean
    icon?: string
    iconSize?: number
    color?: string
    hilightColor?: string
    border?: boolean
    disabled?: boolean
    padding?: number
    minHeight?: number
    type?: "primary" | "transparent" | "danger" | undefined
    loading?: boolean
  }>(),
  {
    iconSize: 16,
    iconButton: false,
    border: true,
    disabled: false,
    padding: 12,
    minHeight: 34,
    loading: false,
  }
)

const state = reactive({
  hover: false,
})

const hasIcon = computed(() => {
  return props.icon && props.icon.length > 0
})

const loadingColor = computed(() => {
  switch (props.type) {
    case "primary":
      return "#fff"
    default:
      return "#2593f2"
  }
})

const buttonClass = computed(() => {
  let result = {
    "flex-row-nowrap-center-center-center": true,
    "ec-button-container": true,
    "ec-button-border": props.border,
    "ec-button-disabled": props.disabled,
  } as any
  switch (props.type) {
    case "primary":
      result["ec-button-container-primary"] = true
      break
    case "transparent":
      result["ec-button-container-transparent"] = true
      break
    default:
      result["ec-button-container-default"] = true
      break
  }
  return result
})

const buttonStyle = computed(() => {
  return {
    "padding-left": `${props.padding}px`,
    "padding-right": `${props.padding}px`,
    "min-height": `${props.minHeight}px`,
  }
})

const buttonTextStyle = computed(() => {
  let color = "#333"
  if (props.type) {
    switch (props.type) {
      case "primary":
        color = "#ffffff"
        break
      case "danger":
        color = "red"
        break
      default:
    }
  }
  if (props.color) {
    color = props.color
  }
  if (state.hover && props.hilightColor) {
    color = props.hilightColor
  }
  if (props.disabled) {
    color = "#b0b0b0"
  }
  return {
    color: color,
    transition: "color 300ms",
    "flex-shirink": 0,
  }
})
</script>
<style scoped>
.ec-button-container {
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  width: fit-content;
}

.ec-button-container-default {
  background-color: #ffffff;
}
.ec-button-container-default:hover {
  background-color: #dbdbdb;
  transition: 300ms;
}

.ec-button-container-transparent {
}

.ec-button-container-transparent:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: 300ms;
}

.ec-button-container-primary:hover {
  background-color: #2d86d3;
  transition: 300ms;
}

.ec-button-container-primary {
  background-color: #2593f2;
  transition: 300ms;
}

.ec-button-border {
  border: 1px solid #dbdbdb;
}

.ec-button-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
