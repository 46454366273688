import { getMyUser } from "@/user/api/UserApi"
import { User } from "@/user/model/UserModel"
import { defineStore } from "pinia"
import { reactive, ref, computed } from "vue"
import * as Sentry from "@sentry/vue"

export const useUserStore = defineStore("user", () => {
  const state = reactive({
    user: {
      id: "",
      name: "",
      email: "",
      phone: "",
      isAdmin: false,
      avatarUrl: "",
    } as User,
    auth: {
      accessToken: "",
      refreshToken: "",
    },
  })

  const auth = computed(() => state.auth)

  const user = computed(() => state.user)

  const getAccessToken = () => {
    return {
      accessToken: localStorage.getItem("accessToken") || "",
      refreshToken: localStorage.getItem("refreshToken") || "",
    }
  }

  const login = (accessToken: string, refreshToken: string) => {
    auth.value.accessToken = accessToken
    auth.value.refreshToken = refreshToken
    localStorage.setItem("accessToken", accessToken)
    localStorage.setItem("refreshToken", refreshToken)
  }

  const refreshToken = (accessToken: string) => {
    auth.value.accessToken = accessToken
    localStorage.setItem("accessToken", accessToken)
  }

  const signout = () => {
    auth.value.accessToken = ""
    auth.value.refreshToken = ""
    localStorage.setItem("accessToken", "")
    localStorage.setItem("refreshToken", "")
    try {
      Sentry.setUser(null)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  const reloadUser = async () => {
    try {
      state.user = await getMyUser()
      try {
        Sentry.setUser({ id: state.user.id, username: state.user.name })
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } catch (error) {
      return error
    }
  }

  return {
    user,
    auth,
    getAccessToken,
    reloadUser,
    login,
    refreshToken,
    signout,
  }
})
