<template>
  <n-input class="container" style="max-width: 200px" :value="value" @update:value="onUpdateValue" :placeholder="placeholder" clearable @keyup="onKeyup" @clear="onClear"></n-input>
</template>
<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    value: string
    placeholder?: string
  }>(),
  {
    placeholder: "",
  }
)
const emits = defineEmits<{
  (e: "update:value", value: string): void
  (e: "search"): void
}>()

const onUpdateValue = (value: string) => {
  emits("update:value", value)
}

const onKeyup = (e: any) => {
  if (e.key == "Enter") {
    emits("search")
  }
}

const onClear = () => {
  emits("update:value", "")
  emits("search")
}
</script>
<style scoped>
.container {
  margin-right: 8px;
}
</style>
