<template>
  <component :is="currentComponent" v-if="currentComponent" ref="svgRef" v-bind="$attrs" />
</template>
<script setup lang="ts">
import type { Component } from "vue"
import { useAttrs, ref, getCurrentInstance, nextTick, watch } from "vue"
import { computed } from "vue"
import { endsWith } from "@/util/StringUtils"

const props = withDefaults(
  defineProps<{
    name: string
    size?: number
    color?: string
    clickable?: boolean
    highlight?: boolean
    highlightColor?: string
    disabled?: boolean
  }>(),
  {
    size: 20,
    clickable: false,
    highlight: false,
    disabled: false,
  }
)

const containerStyle = computed(() => {
  let result = {
    width: `${props.size}px`,
    height: `${props.size}px`,
  } as any
  return result
})

const iconStyle = computed(() => {
  let result = {} as any
  if (props.clickable) {
    result["cursor"] = "pointer"
  }
  if (props.color) {
    result["fill"] = props.color
    result["stroke"] = props.color
  }
  if (props.highlight) {
    result["fill"] = props.highlightColor
    result["stroke"] = props.highlightColor
  }
  if (props.disabled) {
    result["fill"] = "#b0b0b0"
    result["stroke"] = "#b0b0b0"
  }
  if (props.highlightColor) {
    result["--fill-hover"] = props.highlightColor
  }
  return result
})

const modules = import.meta.glob("@/assets/svg/**/*.svg", {
  as: "component",
  eager: true,
})

const currentComponent = computed<Component>(() => {
  const fileName = "/" + props.name + ".svg"
  for (const path in modules) {
    const mod = modules[path]
    if (endsWith(path, fileName)) {
      return mod as Component
    }
  }
  throw new Error("not found svg file:" + fileName)
})

// data-v-hash
const attrs = useAttrs()
const instance = getCurrentInstance()
const svgRef = ref()
let scopeId = ""
if (instance?.type) {
  // __scopeId 存在的条件是 <style scoped>
  const __scopeId = (instance?.type as { __scopeId?: string })?.__scopeId
  if (__scopeId) {
    scopeId = __scopeId
  }
}

const attachAttr = async () => {
  await nextTick()
  // 取到 svg dom
  const cpt = svgRef.value
  if (!cpt) return
  let svg = cpt.$el as Element | undefined
  if (!(svg instanceof Element)) {
    //首次拿到的可能是svg前的#text节点，所以取下一个节点看看是不是svg
    svg = cpt.$el.nextElementSibling as Element | undefined
    if (!(svg instanceof Element)) {
      return
    }
  }

  // 由于svg不在vue_template里,所以初始没有添加样式隔离,需要手动给svg和所有子dom添加 data-v-hash
  if (scopeId) {
    svg.setAttribute(scopeId, "")
    //设置尺寸
    let height = svg.getAttribute("height")
    let width = svg.getAttribute("width")
    if (height && width) {
      width = `${(props.size / parseFloat(height)) * parseFloat(width)}px`
      svg.setAttribute("width", `${parseInt(width)}px`)
    }
    svg.setAttribute("height", `${props.size}px`)

    //设置样式
    let styleStr = ""
    for (const key in iconStyle.value) {
      styleStr += `${key}:${iconStyle.value[key]};`
    }
    svg.setAttribute("style", styleStr)
    svg.querySelectorAll("*").forEach((element) => {
      element.setAttribute(scopeId, "")
    })
  }
}

watch(
  () => props,
  async () => {
    await nextTick()
    attachAttr()
  },
  {
    immediate: true,
    deep: true,
  }
)
</script>
<style scoped>
svg,
path {
  transition: fill 100ms;
}
</style>
