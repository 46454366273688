<template>
  <div>
    <input v-if="directory" class="input-style" webkitdirectory directory :multiple="multiple" type="file" value :id="state.inputId" @change="onSelectFile" />
    <input v-if="!directory" class="input-style" :multiple="multiple" type="file" value :id="state.inputId" @change="onSelectFile" />
  </div>
</template>
<script lang="ts" setup>
import { reactive } from "vue"
import { v4 as uuid } from "uuid"
import { FileWrapper } from "@/components/fileSelect/FileWrapper"
import { MimeDetector } from "@/util/MimeUtils"

const props = withDefaults(
  defineProps<{
    multiple?: boolean
    directory?: boolean
  }>(),
  {
    multiple: false,
    directory: false,
  }
)
const emits = defineEmits<{
  (e: "selected", results: Array<FileWrapper>): void
}>()

const state = reactive({
  inputId: uuid(),
})

const onSelectFile = async (e) => {
  let files: File[] = e.target.files
  let result = [] as Array<FileWrapper>
  for (const file of files) {
    if (file.name.toLowerCase() == ".ds_store") {
      continue
    }
    let detector = new MimeDetector()
    let mime = ""
    if (!file.type || file.type.length == 0) {
      mime = await detector.detectFile(file)
    } else {
      mime = file.type
    }
    let wrapper = {
      id: uuid(),
      file: file,
      mime: mime,
    } as FileWrapper
    result.push(wrapper)
  }
  let input = document.getElementById(state.inputId)
  if (input) {
    input.value = ""
  }
  emits("selected", result)
}

const open = () => {
  document.getElementById(state.inputId)?.click()
}

defineExpose({
  open,
})
</script>
<style scoped>
.input-style {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  display: inline;
  position: absolute;
}
</style>
