import { startsWith } from "@/util/StringUtils"
import { sm2 } from "sm-crypto"

const cipherMode = 1 // 1 - C1C3C2，0 - C1C2C3，默认为1

export function encrypt(message: string): string {
  const publicKey = (window as any).eightcatty.encryptPublicKey
  return encryptToHex(message, publicKey)
}

export function encryptToHex(message: string, publicKey: string): string {
  if (message === "") {
    return ""
  }
  let result = sm2.doEncrypt(message, publicKey, cipherMode)
  //GM/T 0003.4-2012 规范要求密文带前缀 04
  if (!startsWith(result, "04")) {
    result = "04" + result
  }
  return result
}

export function decryptFromHex(encryptedData: string, privateKey: string): string {
  if (encryptedData === "") {
    return ""
  }
  let data = encryptedData
  //GM/T 0003.4-2012 规范要求密文带前缀 04，但sm-crypto内部会自动加上04前缀，故在此去掉04前缀
  if (startsWith(encryptedData, "04")) {
    data = encryptedData.slice(2)
  }
  return sm2.doDecrypt(data, privateKey, cipherMode)
}

export function generateKeyPair() {
  const keyPair = sm2.generateKeyPairHex()
  return {
    publicKey: keyPair.publicKey,
    privateKey: keyPair.privateKey,
  }
}
