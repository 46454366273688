<template>
  <n-select :value="selection" :options="selections" placeholder="请选择" @update:value="onUpdateSelection"> </n-select>
</template>
<script lang="ts" setup>
import { watch, computed } from "vue"
import { getAddressesByParentCode } from "@/common/address/AddressUtil"
import { SelectionItem } from "@/common/model/SelectionItem"

const props = defineProps<{
  code: number
  parentCode: number
}>()
const emits = defineEmits<{
  "update:code": [code: number]
}>()

const selection = computed(() => {
  if (props.code === 0) {
    return ""
  } else {
    return props.code + ""
  }
})

const getSelectionsByParentCode = (parentCode: number): Array<SelectionItem> => {
  const addresses = getAddressesByParentCode(parentCode)
  const result = [] as Array<SelectionItem>
  for (const address of addresses) {
    result.push({ label: address.name, value: address.code + "" })
  }
  return result
}

const selections = computed(() => getSelectionsByParentCode(props.parentCode))

const onUpdateSelection = (selection: string) => {
  emits("update:code", Number.parseInt(selection))
}

watch(
  () => props.parentCode,
  () => {
    onUpdateSelection(0 + "")
  }
)
</script>
<style scoped></style>
