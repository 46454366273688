import { createRouter, createWebHistory, createWebHashHistory } from "vue-router"

import Routes from "@/router/Routes"

let home = undefined as any
const routes = [] as any[]
const children = [] as any[]

const loopRoutes = (result: Array<any>, children: Array<any>, route: any) => {
  if (route.path && route.path.length > 0) {
    const page = {
      path: route.path,
      name: route.name,
      meta: {
        requireAuth: route.requireAuth,
        isHome: route.isHome ?? false,
      },
      component: route.component,
    } as any
    if (page.name === "Home") {
      home = page
    }
    if (route.props) {
      page.props = true
    }
    if (route.single) {
      result.push(page)
    } else {
      children.push(page)
    }
  } else {
    for (const key in route) {
      loopRoutes(result, children, (route as any)[key] as any)
    }
  }
}

loopRoutes(routes, children, Routes)

if (home) {
  home.children = children
  home.redirect = Routes.Resource.ResourceCenter.path
}

routes.push({
  path: "/:pathMatch(.*)",
  redirect: Routes.Common.NotFound.path,
})

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to: any, from: any, next: any) => {
  if (to.meta.requireAuth) {
    const token = localStorage.getItem("accessToken")
    if (token === "null" || token === "" || token === undefined || token === null) {
      next({
        path: Routes.Auth.Login.path,
        query: { redirect: encodeURIComponent(to.fullPath) },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
