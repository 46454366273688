function formatIntegerInput(text: string): string {
  //移除所有小数点与非数字字符
  return text.replace(/[^\d^-]/g, "")
}

function formatDecimalInput(text: string): string {
  //去掉非数字及小数点
  let result = text.replace(/[^\d\\.^-]/g, "")
  //不允许首位为小数点
  result = result.replace(/^\.+/g, "")
  //移除第2-n个小数点
  result = result.replace(/(?<=^\d+\..*)\./g, "")
  return result
}

function formatDecimal(text: string): string {
  let result = formatDecimalInput(text)
  //移除开头无意义的0
  result = result.replace(/^0+(?=(([1-9]\d+\.)|(0\.)))/g, "")
  //移除末尾无意义的0
  if (result !== "-") {
    result = String(Number(result))
  }
  // console.log("3", text, result)
  //处理小数点后全为0的情况，改为整数字符串
  const regResult = /\d+\.0*/g.exec(result)
  if (regResult && regResult.length > 0 && regResult[0].length == result.length) {
    result = result.replace(/\.0*/g, "")
  }
  //处理为整数的情况
  const intResult = /\d+/g.exec(result)
  if (intResult && intResult.length > 0 && intResult[0].length == result.length) {
    result = formatInteger(result)
  }

  return result
}

function formatInteger(text: string): string {
  let result = formatIntegerInput(text)
  //移除开头无意义的0
  if (result !== "-") {
    result = String(Number(result))
  }
  return result
}

const testD = (text: string, expected: string) => {
  const result = formatDecimal(text)
  console.log("dec", result === expected, text, result)
}
const testI = (text: string, expected: string) => {
  const result = formatInteger(text)
  console.log("int", result === expected, text, result)
}

// testD("00", "0")
// testD("00.", "0")
// testD("00.00", "0")
// testD("00.3003", "0.3003")
// testD("00.300309900", "0.3003099")
// testD("0220.300309900", "220.3003099")

// testD("-00", "0")
// testD("-00.", "0")
// testD("-00.00", "0")
// testD("-00.3003", "-0.3003")
// testD("-00.300309900", "-0.3003099")
// testD("-0220.300309900", "-220.3003099")

// testI("10000", "10000")
// testI("0010000", "10000")
// testI("0", "0")
// testI("00000", "0")

// testI("-10000", "-10000")
// testI("-0010000", "-10000")
// testI("-0", "0")
// testI("-00000", "0")

export default {
  formatIntegerInput,
  formatDecimalInput,
  formatInteger,
  formatDecimal,
}
