export const addresses = [
	{ code: 450000000000, parentCode: 0, name: "广西壮族自治区"},
	{ code: 450100000000, parentCode: 450000000000, name: "南宁市"},
	{ code: 450102000000, parentCode: 450100000000, name: "兴宁区"},
	{ code: 450102001000, parentCode: 450102000000, name: "民生街道"},
	{ code: 450102002000, parentCode: 450102000000, name: "朝阳街道"},
	{ code: 450102003000, parentCode: 450102000000, name: "兴东街道"},
	{ code: 450102101000, parentCode: 450102000000, name: "三塘镇"},
	{ code: 450102102000, parentCode: 450102000000, name: "五塘镇"},
	{ code: 450102104000, parentCode: 450102000000, name: "昆仑镇"},
	{ code: 450103000000, parentCode: 450100000000, name: "青秀区"},
	{ code: 450103001000, parentCode: 450103000000, name: "新竹街道"},
	{ code: 450103002000, parentCode: 450103000000, name: "中山街道"},
	{ code: 450103003000, parentCode: 450103000000, name: "建政街道"},
	{ code: 450103004000, parentCode: 450103000000, name: "南湖街道"},
	{ code: 450103005000, parentCode: 450103000000, name: "津头街道"},
	{ code: 450103100000, parentCode: 450103000000, name: "刘圩镇"},
	{ code: 450103101000, parentCode: 450103000000, name: "南阳镇"},
	{ code: 450103102000, parentCode: 450103000000, name: "伶俐镇"},
	{ code: 450103103000, parentCode: 450103000000, name: "长塘镇"},
	{ code: 450103400000, parentCode: 450103000000, name: "仙葫经济开发区"},
	{ code: 450103451000, parentCode: 450103000000, name: "青秀山"},
	{ code: 450105000000, parentCode: 450100000000, name: "江南区"},
	{ code: 450105001000, parentCode: 450105000000, name: "福建园街道"},
	{ code: 450105002000, parentCode: 450105000000, name: "江南街道"},
	{ code: 450105003000, parentCode: 450105000000, name: "沙井街道"},
	{ code: 450105004000, parentCode: 450105000000, name: "那洪街道"},
	{ code: 450105005000, parentCode: 450105000000, name: "金凯街道"},
	{ code: 450105102000, parentCode: 450105000000, name: "吴圩镇"},
	{ code: 450105103000, parentCode: 450105000000, name: "苏圩镇"},
	{ code: 450105104000, parentCode: 450105000000, name: "延安镇"},
	{ code: 450105105000, parentCode: 450105000000, name: "江西镇"},
	{ code: 450105450000, parentCode: 450105000000, name: "经济技术开发区"},
	{ code: 450105451000, parentCode: 450105000000, name: "明阳工业园区"},
	{ code: 450107000000, parentCode: 450100000000, name: "西乡塘区"},
	{ code: 450107001000, parentCode: 450107000000, name: "衡阳街道"},
	{ code: 450107002000, parentCode: 450107000000, name: "北湖街道"},
	{ code: 450107003000, parentCode: 450107000000, name: "西乡塘街道"},
	{ code: 450107004000, parentCode: 450107000000, name: "安吉街道"},
	{ code: 450107005000, parentCode: 450107000000, name: "华强街道"},
	{ code: 450107006000, parentCode: 450107000000, name: "新阳街道"},
	{ code: 450107007000, parentCode: 450107000000, name: "上尧街道"},
	{ code: 450107008000, parentCode: 450107000000, name: "安宁街道"},
	{ code: 450107009000, parentCode: 450107000000, name: "石埠街道"},
	{ code: 450107010000, parentCode: 450107000000, name: "心圩街道"},
	{ code: 450107100000, parentCode: 450107000000, name: "金陵镇"},
	{ code: 450107101000, parentCode: 450107000000, name: "双定镇"},
	{ code: 450107105000, parentCode: 450107000000, name: "坛洛镇"},
	{ code: 450107450000, parentCode: 450107000000, name: "那龙矿务局"},
	{ code: 450107454000, parentCode: 450107000000, name: "高新技术开发区"},
	{ code: 450108000000, parentCode: 450100000000, name: "良庆区"},
	{ code: 450108001000, parentCode: 450108000000, name: "大沙田街道"},
	{ code: 450108002000, parentCode: 450108000000, name: "玉洞街道"},
	{ code: 450108100000, parentCode: 450108000000, name: "良庆镇"},
	{ code: 450108101000, parentCode: 450108000000, name: "那马镇"},
	{ code: 450108102000, parentCode: 450108000000, name: "那陈镇"},
	{ code: 450108103000, parentCode: 450108000000, name: "大塘镇"},
	{ code: 450108104000, parentCode: 450108000000, name: "南晓镇"},
	{ code: 450108452000, parentCode: 450108000000, name: "良庆经济开发区"},
	{ code: 450109000000, parentCode: 450100000000, name: "邕宁区"},
	{ code: 450109100000, parentCode: 450109000000, name: "蒲庙镇"},
	{ code: 450109101000, parentCode: 450109000000, name: "那楼镇"},
	{ code: 450109102000, parentCode: 450109000000, name: "新江镇"},
	{ code: 450109103000, parentCode: 450109000000, name: "百济镇"},
	{ code: 450109104000, parentCode: 450109000000, name: "中和镇"},
	{ code: 450110000000, parentCode: 450100000000, name: "武鸣区"},
	{ code: 450110100000, parentCode: 450110000000, name: "城厢镇"},
	{ code: 450110102000, parentCode: 450110000000, name: "太平镇"},
	{ code: 450110103000, parentCode: 450110000000, name: "双桥镇"},
	{ code: 450110104000, parentCode: 450110000000, name: "宁武镇"},
	{ code: 450110105000, parentCode: 450110000000, name: "锣圩镇"},
	{ code: 450110106000, parentCode: 450110000000, name: "仙湖镇"},
	{ code: 450110107000, parentCode: 450110000000, name: "府城镇"},
	{ code: 450110108000, parentCode: 450110000000, name: "陆斡镇"},
	{ code: 450110109000, parentCode: 450110000000, name: "两江镇"},
	{ code: 450110110000, parentCode: 450110000000, name: "罗波镇"},
	{ code: 450110111000, parentCode: 450110000000, name: "灵马镇"},
	{ code: 450110112000, parentCode: 450110000000, name: "甘圩镇"},
	{ code: 450110113000, parentCode: 450110000000, name: "马头镇"},
	{ code: 450110451000, parentCode: 450110000000, name: "南宁华侨投资区"},
	{ code: 450123000000, parentCode: 450100000000, name: "隆安县"},
	{ code: 450123100000, parentCode: 450123000000, name: "城厢镇"},
	{ code: 450123101000, parentCode: 450123000000, name: "南圩镇"},
	{ code: 450123102000, parentCode: 450123000000, name: "雁江镇"},
	{ code: 450123103000, parentCode: 450123000000, name: "那桐镇"},
	{ code: 450123104000, parentCode: 450123000000, name: "乔建镇"},
	{ code: 450123105000, parentCode: 450123000000, name: "丁当镇"},
	{ code: 450123201000, parentCode: 450123000000, name: "古潭乡"},
	{ code: 450123203000, parentCode: 450123000000, name: "都结乡"},
	{ code: 450123204000, parentCode: 450123000000, name: "布泉乡"},
	{ code: 450123205000, parentCode: 450123000000, name: "屏山乡"},
	{ code: 450123450000, parentCode: 450123000000, name: "隆安华侨管理区"},
	{ code: 450124000000, parentCode: 450100000000, name: "马山县"},
	{ code: 450124100000, parentCode: 450124000000, name: "白山镇"},
	{ code: 450124101000, parentCode: 450124000000, name: "百龙滩镇"},
	{ code: 450124102000, parentCode: 450124000000, name: "林圩镇"},
	{ code: 450124103000, parentCode: 450124000000, name: "古零镇"},
	{ code: 450124104000, parentCode: 450124000000, name: "金钗镇"},
	{ code: 450124105000, parentCode: 450124000000, name: "周鹿镇"},
	{ code: 450124106000, parentCode: 450124000000, name: "永州镇"},
	{ code: 450124200000, parentCode: 450124000000, name: "乔利乡"},
	{ code: 450124201000, parentCode: 450124000000, name: "加方乡"},
	{ code: 450124202000, parentCode: 450124000000, name: "古寨瑶族乡"},
	{ code: 450124203000, parentCode: 450124000000, name: "里当瑶族乡"},
	{ code: 450125000000, parentCode: 450100000000, name: "上林县"},
	{ code: 450125100000, parentCode: 450125000000, name: "大丰镇"},
	{ code: 450125101000, parentCode: 450125000000, name: "明亮镇"},
	{ code: 450125102000, parentCode: 450125000000, name: "巷贤镇"},
	{ code: 450125103000, parentCode: 450125000000, name: "白圩镇"},
	{ code: 450125104000, parentCode: 450125000000, name: "三里镇"},
	{ code: 450125105000, parentCode: 450125000000, name: "乔贤镇"},
	{ code: 450125106000, parentCode: 450125000000, name: "西燕镇"},
	{ code: 450125200000, parentCode: 450125000000, name: "澄泰乡"},
	{ code: 450125202000, parentCode: 450125000000, name: "木山乡"},
	{ code: 450125203000, parentCode: 450125000000, name: "塘红乡"},
	{ code: 450125205000, parentCode: 450125000000, name: "镇圩瑶族乡"},
	{ code: 450126000000, parentCode: 450100000000, name: "宾阳县"},
	{ code: 450126100000, parentCode: 450126000000, name: "宾州镇"},
	{ code: 450126101000, parentCode: 450126000000, name: "黎塘镇"},
	{ code: 450126102000, parentCode: 450126000000, name: "甘棠镇"},
	{ code: 450126103000, parentCode: 450126000000, name: "思陇镇"},
	{ code: 450126104000, parentCode: 450126000000, name: "新桥镇"},
	{ code: 450126105000, parentCode: 450126000000, name: "新圩镇"},
	{ code: 450126106000, parentCode: 450126000000, name: "邹圩镇"},
	{ code: 450126107000, parentCode: 450126000000, name: "大桥镇"},
	{ code: 450126108000, parentCode: 450126000000, name: "武陵镇"},
	{ code: 450126109000, parentCode: 450126000000, name: "中华镇"},
	{ code: 450126110000, parentCode: 450126000000, name: "古辣镇"},
	{ code: 450126111000, parentCode: 450126000000, name: "露圩镇"},
	{ code: 450126112000, parentCode: 450126000000, name: "王灵镇"},
	{ code: 450126113000, parentCode: 450126000000, name: "和吉镇"},
	{ code: 450126114000, parentCode: 450126000000, name: "洋桥镇"},
	{ code: 450126115000, parentCode: 450126000000, name: "陈平镇"},
	{ code: 450126451000, parentCode: 450126000000, name: "廖平农场"},
	{ code: 450181000000, parentCode: 450100000000, name: "横州市"},
	{ code: 450181100000, parentCode: 450181000000, name: "横州镇"},
	{ code: 450181101000, parentCode: 450181000000, name: "百合镇"},
	{ code: 450181102000, parentCode: 450181000000, name: "那阳镇"},
	{ code: 450181103000, parentCode: 450181000000, name: "南乡镇"},
	{ code: 450181104000, parentCode: 450181000000, name: "新福镇"},
	{ code: 450181105000, parentCode: 450181000000, name: "莲塘镇"},
	{ code: 450181106000, parentCode: 450181000000, name: "平马镇"},
	{ code: 450181107000, parentCode: 450181000000, name: "峦城镇"},
	{ code: 450181108000, parentCode: 450181000000, name: "六景镇"},
	{ code: 450181110000, parentCode: 450181000000, name: "石塘镇"},
	{ code: 450181112000, parentCode: 450181000000, name: "陶圩镇"},
	{ code: 450181113000, parentCode: 450181000000, name: "校椅镇"},
	{ code: 450181114000, parentCode: 450181000000, name: "云表镇"},
	{ code: 450181115000, parentCode: 450181000000, name: "马岭镇"},
	{ code: 450181118000, parentCode: 450181000000, name: "马山镇"},
	{ code: 450181119000, parentCode: 450181000000, name: "平朗镇"},
	{ code: 450181204000, parentCode: 450181000000, name: "镇龙乡"},
	{ code: 450181400000, parentCode: 450181000000, name: "南宁六景工业园区"},
	{ code: 450200000000, parentCode: 450000000000, name: "柳州市"},
	{ code: 450202000000, parentCode: 450200000000, name: "城中区"},
	{ code: 450202001000, parentCode: 450202000000, name: "城中街道"},
	{ code: 450202002000, parentCode: 450202000000, name: "公园街道"},
	{ code: 450202003000, parentCode: 450202000000, name: "中南街道"},
	{ code: 450202004000, parentCode: 450202000000, name: "沿江街道"},
	{ code: 450202005000, parentCode: 450202000000, name: "潭中街道"},
	{ code: 450202006000, parentCode: 450202000000, name: "河东街道"},
	{ code: 450202007000, parentCode: 450202000000, name: "静兰街道"},
	{ code: 450203000000, parentCode: 450200000000, name: "鱼峰区"},
	{ code: 450203001000, parentCode: 450203000000, name: "天马街道"},
	{ code: 450203002000, parentCode: 450203000000, name: "驾鹤街道"},
	{ code: 450203003000, parentCode: 450203000000, name: "箭盘山街道"},
	{ code: 450203004000, parentCode: 450203000000, name: "五里亭街道"},
	{ code: 450203005000, parentCode: 450203000000, name: "荣军街道"},
	{ code: 450203006000, parentCode: 450203000000, name: "白莲街道"},
	{ code: 450203007000, parentCode: 450203000000, name: "麒麟街道"},
	{ code: 450203008000, parentCode: 450203000000, name: "阳和街道"},
	{ code: 450203100000, parentCode: 450203000000, name: "雒容镇"},
	{ code: 450203101000, parentCode: 450203000000, name: "洛埠镇"},
	{ code: 450203102000, parentCode: 450203000000, name: "白沙镇"},
	{ code: 450203103000, parentCode: 450203000000, name: "里雍镇"},
	{ code: 450204000000, parentCode: 450200000000, name: "柳南区"},
	{ code: 450204001000, parentCode: 450204000000, name: "河西街道"},
	{ code: 450204002000, parentCode: 450204000000, name: "南站街道"},
	{ code: 450204003000, parentCode: 450204000000, name: "鹅山街道"},
	{ code: 450204004000, parentCode: 450204000000, name: "柳南街道"},
	{ code: 450204005000, parentCode: 450204000000, name: "柳石街道"},
	{ code: 450204006000, parentCode: 450204000000, name: "银山街道"},
	{ code: 450204007000, parentCode: 450204000000, name: "潭西街道"},
	{ code: 450204008000, parentCode: 450204000000, name: "南环街道"},
	{ code: 450204100000, parentCode: 450204000000, name: "太阳村镇"},
	{ code: 450204101000, parentCode: 450204000000, name: "洛满镇"},
	{ code: 450204102000, parentCode: 450204000000, name: "流山镇"},
	{ code: 450205000000, parentCode: 450200000000, name: "柳北区"},
	{ code: 450205001000, parentCode: 450205000000, name: "解放街道"},
	{ code: 450205002000, parentCode: 450205000000, name: "雅儒街道"},
	{ code: 450205003000, parentCode: 450205000000, name: "胜利街道"},
	{ code: 450205004000, parentCode: 450205000000, name: "雀儿山街道"},
	{ code: 450205005000, parentCode: 450205000000, name: "钢城街道"},
	{ code: 450205006000, parentCode: 450205000000, name: "锦绣街道"},
	{ code: 450205007000, parentCode: 450205000000, name: "白露街道"},
	{ code: 450205008000, parentCode: 450205000000, name: "跃进街道"},
	{ code: 450205009000, parentCode: 450205000000, name: "柳长街道"},
	{ code: 450205100000, parentCode: 450205000000, name: "石碑坪镇"},
	{ code: 450205101000, parentCode: 450205000000, name: "沙塘镇"},
	{ code: 450205102000, parentCode: 450205000000, name: "长塘镇"},
	{ code: 450206000000, parentCode: 450200000000, name: "柳江区"},
	{ code: 450206100000, parentCode: 450206000000, name: "拉堡镇"},
	{ code: 450206102000, parentCode: 450206000000, name: "百朋镇"},
	{ code: 450206103000, parentCode: 450206000000, name: "成团镇"},
	{ code: 450206106000, parentCode: 450206000000, name: "三都镇"},
	{ code: 450206107000, parentCode: 450206000000, name: "里高镇"},
	{ code: 450206108000, parentCode: 450206000000, name: "进德镇"},
	{ code: 450206109000, parentCode: 450206000000, name: "穿山镇"},
	{ code: 450206110000, parentCode: 450206000000, name: "土博镇"},
	{ code: 450222000000, parentCode: 450200000000, name: "柳城县"},
	{ code: 450222100000, parentCode: 450222000000, name: "大埔镇"},
	{ code: 450222101000, parentCode: 450222000000, name: "龙头镇"},
	{ code: 450222102000, parentCode: 450222000000, name: "太平镇"},
	{ code: 450222103000, parentCode: 450222000000, name: "沙埔镇"},
	{ code: 450222104000, parentCode: 450222000000, name: "东泉镇"},
	{ code: 450222105000, parentCode: 450222000000, name: "凤山镇"},
	{ code: 450222106000, parentCode: 450222000000, name: "六塘镇"},
	{ code: 450222107000, parentCode: 450222000000, name: "冲脉镇"},
	{ code: 450222108000, parentCode: 450222000000, name: "寨隆镇"},
	{ code: 450222109000, parentCode: 450222000000, name: "马山镇"},
	{ code: 450222200000, parentCode: 450222000000, name: "古砦仫佬族乡"},
	{ code: 450222202000, parentCode: 450222000000, name: "社冲乡"},
	{ code: 450223000000, parentCode: 450200000000, name: "鹿寨县"},
	{ code: 450223100000, parentCode: 450223000000, name: "鹿寨镇"},
	{ code: 450223102000, parentCode: 450223000000, name: "中渡镇"},
	{ code: 450223103000, parentCode: 450223000000, name: "寨沙镇"},
	{ code: 450223104000, parentCode: 450223000000, name: "平山镇"},
	{ code: 450223105000, parentCode: 450223000000, name: "黄冕镇"},
	{ code: 450223106000, parentCode: 450223000000, name: "四排镇"},
	{ code: 450223201000, parentCode: 450223000000, name: "江口乡"},
	{ code: 450223202000, parentCode: 450223000000, name: "导江乡"},
	{ code: 450223203000, parentCode: 450223000000, name: "拉沟乡"},
	{ code: 450224000000, parentCode: 450200000000, name: "融安县"},
	{ code: 450224100000, parentCode: 450224000000, name: "长安镇"},
	{ code: 450224101000, parentCode: 450224000000, name: "浮石镇"},
	{ code: 450224102000, parentCode: 450224000000, name: "泗顶镇"},
	{ code: 450224103000, parentCode: 450224000000, name: "板榄镇"},
	{ code: 450224104000, parentCode: 450224000000, name: "大将镇"},
	{ code: 450224105000, parentCode: 450224000000, name: "大良镇"},
	{ code: 450224200000, parentCode: 450224000000, name: "雅瑶乡"},
	{ code: 450224201000, parentCode: 450224000000, name: "大坡乡"},
	{ code: 450224202000, parentCode: 450224000000, name: "东起乡"},
	{ code: 450224203000, parentCode: 450224000000, name: "沙子乡"},
	{ code: 450224204000, parentCode: 450224000000, name: "桥板乡"},
	{ code: 450224205000, parentCode: 450224000000, name: "潭头乡"},
	{ code: 450225000000, parentCode: 450200000000, name: "融水苗族自治县"},
	{ code: 450225100000, parentCode: 450225000000, name: "融水镇"},
	{ code: 450225101000, parentCode: 450225000000, name: "和睦镇"},
	{ code: 450225102000, parentCode: 450225000000, name: "三防镇"},
	{ code: 450225103000, parentCode: 450225000000, name: "怀宝镇"},
	{ code: 450225104000, parentCode: 450225000000, name: "洞头镇"},
	{ code: 450225105000, parentCode: 450225000000, name: "大浪镇"},
	{ code: 450225106000, parentCode: 450225000000, name: "永乐镇"},
	{ code: 450225201000, parentCode: 450225000000, name: "四荣乡"},
	{ code: 450225202000, parentCode: 450225000000, name: "香粉乡"},
	{ code: 450225203000, parentCode: 450225000000, name: "安太乡"},
	{ code: 450225205000, parentCode: 450225000000, name: "汪洞乡"},
	{ code: 450225206000, parentCode: 450225000000, name: "同练瑶族乡"},
	{ code: 450225207000, parentCode: 450225000000, name: "滚贝侗族乡"},
	{ code: 450225208000, parentCode: 450225000000, name: "杆洞乡"},
	{ code: 450225209000, parentCode: 450225000000, name: "安陲乡"},
	{ code: 450225211000, parentCode: 450225000000, name: "白云乡"},
	{ code: 450225212000, parentCode: 450225000000, name: "红水乡"},
	{ code: 450225213000, parentCode: 450225000000, name: "拱洞乡"},
	{ code: 450225214000, parentCode: 450225000000, name: "良寨乡"},
	{ code: 450225215000, parentCode: 450225000000, name: "大年乡"},
	{ code: 450226000000, parentCode: 450200000000, name: "三江侗族自治县"},
	{ code: 450226100000, parentCode: 450226000000, name: "古宜镇"},
	{ code: 450226101000, parentCode: 450226000000, name: "斗江镇"},
	{ code: 450226102000, parentCode: 450226000000, name: "丹洲镇"},
	{ code: 450226103000, parentCode: 450226000000, name: "八江镇"},
	{ code: 450226104000, parentCode: 450226000000, name: "林溪镇"},
	{ code: 450226105000, parentCode: 450226000000, name: "独峒镇"},
	{ code: 450226203000, parentCode: 450226000000, name: "同乐苗族乡"},
	{ code: 450226204000, parentCode: 450226000000, name: "梅林乡"},
	{ code: 450226205000, parentCode: 450226000000, name: "富禄苗族乡"},
	{ code: 450226206000, parentCode: 450226000000, name: "洋溪乡"},
	{ code: 450226207000, parentCode: 450226000000, name: "良口乡"},
	{ code: 450226208000, parentCode: 450226000000, name: "老堡乡"},
	{ code: 450226209000, parentCode: 450226000000, name: "高基瑶族乡"},
	{ code: 450226210000, parentCode: 450226000000, name: "和平乡"},
	{ code: 450226211000, parentCode: 450226000000, name: "程村乡"},
	{ code: 450300000000, parentCode: 450000000000, name: "桂林市"},
	{ code: 450302000000, parentCode: 450300000000, name: "秀峰区"},
	{ code: 450302001000, parentCode: 450302000000, name: "秀峰街道"},
	{ code: 450302002000, parentCode: 450302000000, name: "丽君街道"},
	{ code: 450302003000, parentCode: 450302000000, name: "甲山街道"},
	{ code: 450303000000, parentCode: 450300000000, name: "叠彩区"},
	{ code: 450303001000, parentCode: 450303000000, name: "叠彩街道"},
	{ code: 450303002000, parentCode: 450303000000, name: "北门街道"},
	{ code: 450303200000, parentCode: 450303000000, name: "大河乡"},
	{ code: 450304000000, parentCode: 450300000000, name: "象山区"},
	{ code: 450304001000, parentCode: 450304000000, name: "南门街道"},
	{ code: 450304002000, parentCode: 450304000000, name: "象山街道"},
	{ code: 450304003000, parentCode: 450304000000, name: "平山街道"},
	{ code: 450304200000, parentCode: 450304000000, name: "二塘乡"},
	{ code: 450305000000, parentCode: 450300000000, name: "七星区"},
	{ code: 450305001000, parentCode: 450305000000, name: "七星区街道"},
	{ code: 450305002000, parentCode: 450305000000, name: "东江街道"},
	{ code: 450305003000, parentCode: 450305000000, name: "穿山街道"},
	{ code: 450305004000, parentCode: 450305000000, name: "漓东街道"},
	{ code: 450305200000, parentCode: 450305000000, name: "朝阳乡"},
	{ code: 450305400000, parentCode: 450305000000, name: "桂林华侨旅游经济区管理委员会"},
	{ code: 450311000000, parentCode: 450300000000, name: "雁山区"},
	{ code: 450311001000, parentCode: 450311000000, name: "良丰街道"},
	{ code: 450311100000, parentCode: 450311000000, name: "雁山镇"},
	{ code: 450311101000, parentCode: 450311000000, name: "柘木镇"},
	{ code: 450311200000, parentCode: 450311000000, name: "大埠乡"},
	{ code: 450311201000, parentCode: 450311000000, name: "草坪回族乡"},
	{ code: 450312000000, parentCode: 450300000000, name: "临桂区"},
	{ code: 450312100000, parentCode: 450312000000, name: "临桂镇"},
	{ code: 450312101000, parentCode: 450312000000, name: "六塘镇"},
	{ code: 450312102000, parentCode: 450312000000, name: "会仙镇"},
	{ code: 450312103000, parentCode: 450312000000, name: "两江镇"},
	{ code: 450312104000, parentCode: 450312000000, name: "五通镇"},
	{ code: 450312105000, parentCode: 450312000000, name: "四塘镇"},
	{ code: 450312106000, parentCode: 450312000000, name: "南边山镇"},
	{ code: 450312107000, parentCode: 450312000000, name: "中庸镇"},
	{ code: 450312108000, parentCode: 450312000000, name: "茶洞镇"},
	{ code: 450312204000, parentCode: 450312000000, name: "宛田瑶族乡"},
	{ code: 450312205000, parentCode: 450312000000, name: "黄沙瑶族乡"},
	{ code: 450321000000, parentCode: 450300000000, name: "阳朔县"},
	{ code: 450321100000, parentCode: 450321000000, name: "阳朔镇"},
	{ code: 450321101000, parentCode: 450321000000, name: "白沙镇"},
	{ code: 450321102000, parentCode: 450321000000, name: "福利镇"},
	{ code: 450321103000, parentCode: 450321000000, name: "兴坪镇"},
	{ code: 450321104000, parentCode: 450321000000, name: "葡萄镇"},
	{ code: 450321105000, parentCode: 450321000000, name: "高田镇"},
	{ code: 450321200000, parentCode: 450321000000, name: "金宝乡"},
	{ code: 450321201000, parentCode: 450321000000, name: "普益乡"},
	{ code: 450321202000, parentCode: 450321000000, name: "杨堤乡"},
	{ code: 450323000000, parentCode: 450300000000, name: "灵川县"},
	{ code: 450323100000, parentCode: 450323000000, name: "灵川镇"},
	{ code: 450323101000, parentCode: 450323000000, name: "大圩镇"},
	{ code: 450323102000, parentCode: 450323000000, name: "定江镇"},
	{ code: 450323103000, parentCode: 450323000000, name: "三街镇"},
	{ code: 450323104000, parentCode: 450323000000, name: "潭下镇"},
	{ code: 450323106000, parentCode: 450323000000, name: "九屋镇"},
	{ code: 450323107000, parentCode: 450323000000, name: "灵田镇"},
	{ code: 450323200000, parentCode: 450323000000, name: "潮田乡"},
	{ code: 450323201000, parentCode: 450323000000, name: "大境瑶族乡"},
	{ code: 450323202000, parentCode: 450323000000, name: "海洋乡"},
	{ code: 450323206000, parentCode: 450323000000, name: "兰田瑶族乡"},
	{ code: 450323207000, parentCode: 450323000000, name: "公平乡"},
	{ code: 450324000000, parentCode: 450300000000, name: "全州县"},
	{ code: 450324100000, parentCode: 450324000000, name: "全州镇"},
	{ code: 450324101000, parentCode: 450324000000, name: "黄沙河镇"},
	{ code: 450324102000, parentCode: 450324000000, name: "庙头镇"},
	{ code: 450324103000, parentCode: 450324000000, name: "文桥镇"},
	{ code: 450324104000, parentCode: 450324000000, name: "大西江镇"},
	{ code: 450324105000, parentCode: 450324000000, name: "龙水镇"},
	{ code: 450324106000, parentCode: 450324000000, name: "才湾镇"},
	{ code: 450324107000, parentCode: 450324000000, name: "绍水镇"},
	{ code: 450324108000, parentCode: 450324000000, name: "石塘镇"},
	{ code: 450324109000, parentCode: 450324000000, name: "咸水镇"},
	{ code: 450324110000, parentCode: 450324000000, name: "凤凰镇"},
	{ code: 450324111000, parentCode: 450324000000, name: "安和镇"},
	{ code: 450324112000, parentCode: 450324000000, name: "两河镇"},
	{ code: 450324113000, parentCode: 450324000000, name: "枧塘镇"},
	{ code: 450324114000, parentCode: 450324000000, name: "永岁镇"},
	{ code: 450324204000, parentCode: 450324000000, name: "蕉江瑶族乡"},
	{ code: 450324207000, parentCode: 450324000000, name: "白宝乡"},
	{ code: 450324208000, parentCode: 450324000000, name: "东山瑶族乡"},
	{ code: 450325000000, parentCode: 450300000000, name: "兴安县"},
	{ code: 450325100000, parentCode: 450325000000, name: "兴安镇"},
	{ code: 450325101000, parentCode: 450325000000, name: "湘漓镇"},
	{ code: 450325102000, parentCode: 450325000000, name: "界首镇"},
	{ code: 450325103000, parentCode: 450325000000, name: "高尚镇"},
	{ code: 450325104000, parentCode: 450325000000, name: "严关镇"},
	{ code: 450325105000, parentCode: 450325000000, name: "溶江镇"},
	{ code: 450325200000, parentCode: 450325000000, name: "漠川乡"},
	{ code: 450325201000, parentCode: 450325000000, name: "白石乡"},
	{ code: 450325202000, parentCode: 450325000000, name: "崔家乡"},
	{ code: 450325203000, parentCode: 450325000000, name: "华江瑶族乡"},
	{ code: 450326000000, parentCode: 450300000000, name: "永福县"},
	{ code: 450326100000, parentCode: 450326000000, name: "永福镇"},
	{ code: 450326101000, parentCode: 450326000000, name: "罗锦镇"},
	{ code: 450326102000, parentCode: 450326000000, name: "百寿镇"},
	{ code: 450326103000, parentCode: 450326000000, name: "苏桥镇"},
	{ code: 450326104000, parentCode: 450326000000, name: "三皇镇"},
	{ code: 450326105000, parentCode: 450326000000, name: "堡里镇"},
	{ code: 450326202000, parentCode: 450326000000, name: "广福乡"},
	{ code: 450326204000, parentCode: 450326000000, name: "永安乡"},
	{ code: 450326205000, parentCode: 450326000000, name: "龙江乡"},
	{ code: 450327000000, parentCode: 450300000000, name: "灌阳县"},
	{ code: 450327100000, parentCode: 450327000000, name: "灌阳镇"},
	{ code: 450327101000, parentCode: 450327000000, name: "黄关镇"},
	{ code: 450327102000, parentCode: 450327000000, name: "文市镇"},
	{ code: 450327103000, parentCode: 450327000000, name: "新街镇"},
	{ code: 450327104000, parentCode: 450327000000, name: "新圩镇"},
	{ code: 450327105000, parentCode: 450327000000, name: "水车镇"},
	{ code: 450327200000, parentCode: 450327000000, name: "洞井瑶族乡"},
	{ code: 450327201000, parentCode: 450327000000, name: "观音阁乡"},
	{ code: 450327202000, parentCode: 450327000000, name: "西山瑶族乡"},
	{ code: 450328000000, parentCode: 450300000000, name: "龙胜各族自治县"},
	{ code: 450328100000, parentCode: 450328000000, name: "龙胜镇"},
	{ code: 450328101000, parentCode: 450328000000, name: "瓢里镇"},
	{ code: 450328102000, parentCode: 450328000000, name: "三门镇"},
	{ code: 450328103000, parentCode: 450328000000, name: "龙脊镇"},
	{ code: 450328104000, parentCode: 450328000000, name: "平等镇"},
	{ code: 450328105000, parentCode: 450328000000, name: "乐江镇"},
	{ code: 450328201000, parentCode: 450328000000, name: "泗水乡"},
	{ code: 450328202000, parentCode: 450328000000, name: "江底乡"},
	{ code: 450328203000, parentCode: 450328000000, name: "马堤乡"},
	{ code: 450328204000, parentCode: 450328000000, name: "伟江乡"},
	{ code: 450329000000, parentCode: 450300000000, name: "资源县"},
	{ code: 450329100000, parentCode: 450329000000, name: "资源镇"},
	{ code: 450329101000, parentCode: 450329000000, name: "中峰镇"},
	{ code: 450329102000, parentCode: 450329000000, name: "梅溪镇"},
	{ code: 450329202000, parentCode: 450329000000, name: "瓜里乡"},
	{ code: 450329203000, parentCode: 450329000000, name: "车田苗族乡"},
	{ code: 450329204000, parentCode: 450329000000, name: "两水苗族乡"},
	{ code: 450329205000, parentCode: 450329000000, name: "河口瑶族乡"},
	{ code: 450330000000, parentCode: 450300000000, name: "平乐县"},
	{ code: 450330100000, parentCode: 450330000000, name: "平乐镇"},
	{ code: 450330101000, parentCode: 450330000000, name: "二塘镇"},
	{ code: 450330102000, parentCode: 450330000000, name: "沙子镇"},
	{ code: 450330103000, parentCode: 450330000000, name: "同安镇"},
	{ code: 450330104000, parentCode: 450330000000, name: "张家镇"},
	{ code: 450330105000, parentCode: 450330000000, name: "源头镇"},
	{ code: 450330200000, parentCode: 450330000000, name: "阳安乡"},
	{ code: 450330201000, parentCode: 450330000000, name: "青龙乡"},
	{ code: 450330202000, parentCode: 450330000000, name: "桥亭乡"},
	{ code: 450330203000, parentCode: 450330000000, name: "大发瑶族乡"},
	{ code: 450332000000, parentCode: 450300000000, name: "恭城瑶族自治县"},
	{ code: 450332100000, parentCode: 450332000000, name: "恭城镇"},
	{ code: 450332101000, parentCode: 450332000000, name: "栗木镇"},
	{ code: 450332102000, parentCode: 450332000000, name: "莲花镇"},
	{ code: 450332103000, parentCode: 450332000000, name: "嘉会镇"},
	{ code: 450332104000, parentCode: 450332000000, name: "西岭镇"},
	{ code: 450332105000, parentCode: 450332000000, name: "平安镇"},
	{ code: 450332201000, parentCode: 450332000000, name: "三江乡"},
	{ code: 450332204000, parentCode: 450332000000, name: "观音乡"},
	{ code: 450332205000, parentCode: 450332000000, name: "龙虎乡"},
	{ code: 450381000000, parentCode: 450300000000, name: "荔浦市"},
	{ code: 450381100000, parentCode: 450381000000, name: "荔城镇"},
	{ code: 450381101000, parentCode: 450381000000, name: "东昌镇"},
	{ code: 450381102000, parentCode: 450381000000, name: "新坪镇"},
	{ code: 450381103000, parentCode: 450381000000, name: "杜莫镇"},
	{ code: 450381104000, parentCode: 450381000000, name: "青山镇"},
	{ code: 450381105000, parentCode: 450381000000, name: "修仁镇"},
	{ code: 450381106000, parentCode: 450381000000, name: "大塘镇"},
	{ code: 450381107000, parentCode: 450381000000, name: "花箦镇"},
	{ code: 450381108000, parentCode: 450381000000, name: "双江镇"},
	{ code: 450381109000, parentCode: 450381000000, name: "马岭镇"},
	{ code: 450381200000, parentCode: 450381000000, name: "龙怀乡"},
	{ code: 450381201000, parentCode: 450381000000, name: "茶城乡"},
	{ code: 450381202000, parentCode: 450381000000, name: "蒲芦瑶族乡"},
	{ code: 450400000000, parentCode: 450000000000, name: "梧州市"},
	{ code: 450403000000, parentCode: 450400000000, name: "万秀区"},
	{ code: 450403005000, parentCode: 450403000000, name: "角嘴街道"},
	{ code: 450403006000, parentCode: 450403000000, name: "东兴街道"},
	{ code: 450403007000, parentCode: 450403000000, name: "富民街道"},
	{ code: 450403008000, parentCode: 450403000000, name: "城南街道"},
	{ code: 450403009000, parentCode: 450403000000, name: "城北街道"},
	{ code: 450403101000, parentCode: 450403000000, name: "城东镇"},
	{ code: 450403103000, parentCode: 450403000000, name: "龙湖镇"},
	{ code: 450403104000, parentCode: 450403000000, name: "夏郢镇"},
	{ code: 450405000000, parentCode: 450400000000, name: "长洲区"},
	{ code: 450405001000, parentCode: 450405000000, name: "大塘街道"},
	{ code: 450405002000, parentCode: 450405000000, name: "兴龙街道"},
	{ code: 450405003000, parentCode: 450405000000, name: "红岭街道"},
	{ code: 450405101000, parentCode: 450405000000, name: "长洲镇"},
	{ code: 450405102000, parentCode: 450405000000, name: "倒水镇"},
	{ code: 450406000000, parentCode: 450400000000, name: "龙圩区"},
	{ code: 450406100000, parentCode: 450406000000, name: "龙圩镇"},
	{ code: 450406101000, parentCode: 450406000000, name: "大坡镇"},
	{ code: 450406102000, parentCode: 450406000000, name: "广平镇"},
	{ code: 450406103000, parentCode: 450406000000, name: "新地镇"},
	{ code: 450421000000, parentCode: 450400000000, name: "苍梧县"},
	{ code: 450421100000, parentCode: 450421000000, name: "石桥镇"},
	{ code: 450421101000, parentCode: 450421000000, name: "沙头镇"},
	{ code: 450421102000, parentCode: 450421000000, name: "梨埠镇"},
	{ code: 450421105000, parentCode: 450421000000, name: "岭脚镇"},
	{ code: 450421108000, parentCode: 450421000000, name: "京南镇"},
	{ code: 450421109000, parentCode: 450421000000, name: "狮寨镇"},
	{ code: 450421110000, parentCode: 450421000000, name: "旺甫镇"},
	{ code: 450421112000, parentCode: 450421000000, name: "六堡镇"},
	{ code: 450421114000, parentCode: 450421000000, name: "木双镇"},
	{ code: 450422000000, parentCode: 450400000000, name: "藤县"},
	{ code: 450422100000, parentCode: 450422000000, name: "藤州镇"},
	{ code: 450422103000, parentCode: 450422000000, name: "塘步镇"},
	{ code: 450422104000, parentCode: 450422000000, name: "埌南镇"},
	{ code: 450422105000, parentCode: 450422000000, name: "同心镇"},
	{ code: 450422106000, parentCode: 450422000000, name: "金鸡镇"},
	{ code: 450422107000, parentCode: 450422000000, name: "新庆镇"},
	{ code: 450422108000, parentCode: 450422000000, name: "象棋镇"},
	{ code: 450422109000, parentCode: 450422000000, name: "岭景镇"},
	{ code: 450422110000, parentCode: 450422000000, name: "天平镇"},
	{ code: 450422111000, parentCode: 450422000000, name: "濛江镇"},
	{ code: 450422112000, parentCode: 450422000000, name: "和平镇"},
	{ code: 450422113000, parentCode: 450422000000, name: "太平镇"},
	{ code: 450422114000, parentCode: 450422000000, name: "古龙镇"},
	{ code: 450422115000, parentCode: 450422000000, name: "东荣镇"},
	{ code: 450422116000, parentCode: 450422000000, name: "大黎镇"},
	{ code: 450422200000, parentCode: 450422000000, name: "平福乡"},
	{ code: 450422201000, parentCode: 450422000000, name: "宁康乡"},
	{ code: 450423000000, parentCode: 450400000000, name: "蒙山县"},
	{ code: 450423100000, parentCode: 450423000000, name: "蒙山镇"},
	{ code: 450423101000, parentCode: 450423000000, name: "西河镇"},
	{ code: 450423102000, parentCode: 450423000000, name: "新圩镇"},
	{ code: 450423103000, parentCode: 450423000000, name: "文圩镇"},
	{ code: 450423104000, parentCode: 450423000000, name: "黄村镇"},
	{ code: 450423105000, parentCode: 450423000000, name: "陈塘镇"},
	{ code: 450423200000, parentCode: 450423000000, name: "汉豪乡"},
	{ code: 450423201000, parentCode: 450423000000, name: "长坪瑶族乡"},
	{ code: 450423202000, parentCode: 450423000000, name: "夏宜瑶族乡"},
	{ code: 450481000000, parentCode: 450400000000, name: "岑溪市"},
	{ code: 450481100000, parentCode: 450481000000, name: "岑城镇"},
	{ code: 450481102000, parentCode: 450481000000, name: "马路镇"},
	{ code: 450481103000, parentCode: 450481000000, name: "南渡镇"},
	{ code: 450481105000, parentCode: 450481000000, name: "水汶镇"},
	{ code: 450481106000, parentCode: 450481000000, name: "大隆镇"},
	{ code: 450481107000, parentCode: 450481000000, name: "梨木镇"},
	{ code: 450481108000, parentCode: 450481000000, name: "大业镇"},
	{ code: 450481109000, parentCode: 450481000000, name: "筋竹镇"},
	{ code: 450481110000, parentCode: 450481000000, name: "诚谏镇"},
	{ code: 450481111000, parentCode: 450481000000, name: "归义镇"},
	{ code: 450481112000, parentCode: 450481000000, name: "糯垌镇"},
	{ code: 450481113000, parentCode: 450481000000, name: "安平镇"},
	{ code: 450481114000, parentCode: 450481000000, name: "三堡镇"},
	{ code: 450481115000, parentCode: 450481000000, name: "波塘镇"},
	{ code: 450500000000, parentCode: 450000000000, name: "北海市"},
	{ code: 450502000000, parentCode: 450500000000, name: "海城区"},
	{ code: 450502001000, parentCode: 450502000000, name: "中街街道"},
	{ code: 450502002000, parentCode: 450502000000, name: "东街街道"},
	{ code: 450502003000, parentCode: 450502000000, name: "西街街道"},
	{ code: 450502004000, parentCode: 450502000000, name: "海角街道"},
	{ code: 450502005000, parentCode: 450502000000, name: "地角街道"},
	{ code: 450502006000, parentCode: 450502000000, name: "高德街道"},
	{ code: 450502007000, parentCode: 450502000000, name: "驿马街道"},
	{ code: 450502100000, parentCode: 450502000000, name: "涠洲镇"},
	{ code: 450503000000, parentCode: 450500000000, name: "银海区"},
	{ code: 450503100000, parentCode: 450503000000, name: "福成镇"},
	{ code: 450503101000, parentCode: 450503000000, name: "银滩镇"},
	{ code: 450503102000, parentCode: 450503000000, name: "平阳镇"},
	{ code: 450503103000, parentCode: 450503000000, name: "侨港镇"},
	{ code: 450512000000, parentCode: 450500000000, name: "铁山港区"},
	{ code: 450512100000, parentCode: 450512000000, name: "南康镇"},
	{ code: 450512101000, parentCode: 450512000000, name: "营盘镇"},
	{ code: 450512102000, parentCode: 450512000000, name: "兴港镇"},
	{ code: 450521000000, parentCode: 450500000000, name: "合浦县"},
	{ code: 450521100000, parentCode: 450521000000, name: "廉州镇"},
	{ code: 450521101000, parentCode: 450521000000, name: "党江镇"},
	{ code: 450521102000, parentCode: 450521000000, name: "西场镇"},
	{ code: 450521103000, parentCode: 450521000000, name: "沙岗镇"},
	{ code: 450521104000, parentCode: 450521000000, name: "乌家镇"},
	{ code: 450521105000, parentCode: 450521000000, name: "闸口镇"},
	{ code: 450521106000, parentCode: 450521000000, name: "公馆镇"},
	{ code: 450521107000, parentCode: 450521000000, name: "白沙镇"},
	{ code: 450521108000, parentCode: 450521000000, name: "山口镇"},
	{ code: 450521109000, parentCode: 450521000000, name: "沙田镇"},
	{ code: 450521110000, parentCode: 450521000000, name: "石湾镇"},
	{ code: 450521111000, parentCode: 450521000000, name: "石康镇"},
	{ code: 450521112000, parentCode: 450521000000, name: "常乐镇"},
	{ code: 450521113000, parentCode: 450521000000, name: "星岛湖镇"},
	{ code: 450521200000, parentCode: 450521000000, name: "曲樟乡"},
	{ code: 450600000000, parentCode: 450000000000, name: "防城港市"},
	{ code: 450602000000, parentCode: 450600000000, name: "港口区"},
	{ code: 450602001000, parentCode: 450602000000, name: "渔州坪街道"},
	{ code: 450602002000, parentCode: 450602000000, name: "白沙万街道"},
	{ code: 450602003000, parentCode: 450602000000, name: "沙潭江街道"},
	{ code: 450602004000, parentCode: 450602000000, name: "王府街道"},
	{ code: 450602100000, parentCode: 450602000000, name: "企沙镇"},
	{ code: 450602101000, parentCode: 450602000000, name: "光坡镇"},
	{ code: 450603000000, parentCode: 450600000000, name: "防城区"},
	{ code: 450603001000, parentCode: 450603000000, name: "水营街道"},
	{ code: 450603002000, parentCode: 450603000000, name: "珠河街道"},
	{ code: 450603003000, parentCode: 450603000000, name: "文昌街道"},
	{ code: 450603101000, parentCode: 450603000000, name: "大菉镇"},
	{ code: 450603102000, parentCode: 450603000000, name: "华石镇"},
	{ code: 450603103000, parentCode: 450603000000, name: "那梭镇"},
	{ code: 450603104000, parentCode: 450603000000, name: "那良镇"},
	{ code: 450603105000, parentCode: 450603000000, name: "峒中镇"},
	{ code: 450603106000, parentCode: 450603000000, name: "江山镇"},
	{ code: 450603107000, parentCode: 450603000000, name: "茅岭镇"},
	{ code: 450603108000, parentCode: 450603000000, name: "扶隆镇"},
	{ code: 450603203000, parentCode: 450603000000, name: "滩营乡"},
	{ code: 450603209000, parentCode: 450603000000, name: "十万山瑶族乡"},
	{ code: 450603450000, parentCode: 450603000000, name: "小峰经济作物场"},
	{ code: 450621000000, parentCode: 450600000000, name: "上思县"},
	{ code: 450621100000, parentCode: 450621000000, name: "思阳镇"},
	{ code: 450621101000, parentCode: 450621000000, name: "在妙镇"},
	{ code: 450621102000, parentCode: 450621000000, name: "华兰镇"},
	{ code: 450621103000, parentCode: 450621000000, name: "叫安镇"},
	{ code: 450621203000, parentCode: 450621000000, name: "南屏瑶族乡"},
	{ code: 450621204000, parentCode: 450621000000, name: "平福乡"},
	{ code: 450621205000, parentCode: 450621000000, name: "那琴乡"},
	{ code: 450621206000, parentCode: 450621000000, name: "公正乡"},
	{ code: 450681000000, parentCode: 450600000000, name: "东兴市"},
	{ code: 450681100000, parentCode: 450681000000, name: "东兴镇"},
	{ code: 450681101000, parentCode: 450681000000, name: "江平镇"},
	{ code: 450681102000, parentCode: 450681000000, name: "马路镇"},
	{ code: 450700000000, parentCode: 450000000000, name: "钦州市"},
	{ code: 450702000000, parentCode: 450700000000, name: "钦南区"},
	{ code: 450702001000, parentCode: 450702000000, name: "向阳街道"},
	{ code: 450702002000, parentCode: 450702000000, name: "水东街道"},
	{ code: 450702003000, parentCode: 450702000000, name: "文峰街道"},
	{ code: 450702004000, parentCode: 450702000000, name: "南珠街道"},
	{ code: 450702005000, parentCode: 450702000000, name: "尖山街道"},
	{ code: 450702100000, parentCode: 450702000000, name: "沙埠镇"},
	{ code: 450702101000, parentCode: 450702000000, name: "康熙岭镇"},
	{ code: 450702102000, parentCode: 450702000000, name: "黄屋屯镇"},
	{ code: 450702104000, parentCode: 450702000000, name: "大番坡镇"},
	{ code: 450702105000, parentCode: 450702000000, name: "龙门港镇"},
	{ code: 450702107000, parentCode: 450702000000, name: "久隆镇"},
	{ code: 450702108000, parentCode: 450702000000, name: "东场镇"},
	{ code: 450702109000, parentCode: 450702000000, name: "那丽镇"},
	{ code: 450702110000, parentCode: 450702000000, name: "那彭镇"},
	{ code: 450702111000, parentCode: 450702000000, name: "那思镇"},
	{ code: 450702112000, parentCode: 450702000000, name: "犀牛脚镇"},
	{ code: 450702400000, parentCode: 450702000000, name: "丽光华侨农场"},
	{ code: 450702401000, parentCode: 450702000000, name: "钦州港经济技术开发区"},
	{ code: 450702402000, parentCode: 450702000000, name: "三娘湾旅游管理区"},
	{ code: 450702403000, parentCode: 450702000000, name: "广西钦州保税港区"},
	{ code: 450702404000, parentCode: 450702000000, name: "中马钦州产业园区"},
	{ code: 450703000000, parentCode: 450700000000, name: "钦北区"},
	{ code: 450703001000, parentCode: 450703000000, name: "长田街道"},
	{ code: 450703002000, parentCode: 450703000000, name: "鸿亭街道"},
	{ code: 450703003000, parentCode: 450703000000, name: "子材街道"},
	{ code: 450703100000, parentCode: 450703000000, name: "大垌镇"},
	{ code: 450703101000, parentCode: 450703000000, name: "平吉镇"},
	{ code: 450703102000, parentCode: 450703000000, name: "青塘镇"},
	{ code: 450703103000, parentCode: 450703000000, name: "小董镇"},
	{ code: 450703104000, parentCode: 450703000000, name: "板城镇"},
	{ code: 450703105000, parentCode: 450703000000, name: "那蒙镇"},
	{ code: 450703106000, parentCode: 450703000000, name: "长滩镇"},
	{ code: 450703107000, parentCode: 450703000000, name: "新棠镇"},
	{ code: 450703108000, parentCode: 450703000000, name: "大直镇"},
	{ code: 450703109000, parentCode: 450703000000, name: "大寺镇"},
	{ code: 450703110000, parentCode: 450703000000, name: "贵台镇"},
	{ code: 450721000000, parentCode: 450700000000, name: "灵山县"},
	{ code: 450721001000, parentCode: 450721000000, name: "灵城街道"},
	{ code: 450721002000, parentCode: 450721000000, name: "三海街道"},
	{ code: 450721101000, parentCode: 450721000000, name: "新圩镇"},
	{ code: 450721102000, parentCode: 450721000000, name: "丰塘镇"},
	{ code: 450721103000, parentCode: 450721000000, name: "平山镇"},
	{ code: 450721104000, parentCode: 450721000000, name: "石塘镇"},
	{ code: 450721105000, parentCode: 450721000000, name: "佛子镇"},
	{ code: 450721106000, parentCode: 450721000000, name: "平南镇"},
	{ code: 450721107000, parentCode: 450721000000, name: "烟墩镇"},
	{ code: 450721108000, parentCode: 450721000000, name: "檀圩镇"},
	{ code: 450721109000, parentCode: 450721000000, name: "那隆镇"},
	{ code: 450721110000, parentCode: 450721000000, name: "三隆镇"},
	{ code: 450721111000, parentCode: 450721000000, name: "陆屋镇"},
	{ code: 450721112000, parentCode: 450721000000, name: "旧州镇"},
	{ code: 450721113000, parentCode: 450721000000, name: "太平镇"},
	{ code: 450721114000, parentCode: 450721000000, name: "沙坪镇"},
	{ code: 450721115000, parentCode: 450721000000, name: "武利镇"},
	{ code: 450721116000, parentCode: 450721000000, name: "文利镇"},
	{ code: 450721117000, parentCode: 450721000000, name: "伯劳镇"},
	{ code: 450722000000, parentCode: 450700000000, name: "浦北县"},
	{ code: 450722001000, parentCode: 450722000000, name: "小江街道"},
	{ code: 450722002000, parentCode: 450722000000, name: "江城街道"},
	{ code: 450722101000, parentCode: 450722000000, name: "泉水镇"},
	{ code: 450722102000, parentCode: 450722000000, name: "石埇镇"},
	{ code: 450722103000, parentCode: 450722000000, name: "安石镇"},
	{ code: 450722104000, parentCode: 450722000000, name: "张黄镇"},
	{ code: 450722105000, parentCode: 450722000000, name: "大成镇"},
	{ code: 450722106000, parentCode: 450722000000, name: "白石水镇"},
	{ code: 450722107000, parentCode: 450722000000, name: "北通镇"},
	{ code: 450722108000, parentCode: 450722000000, name: "三合镇"},
	{ code: 450722109000, parentCode: 450722000000, name: "龙门镇"},
	{ code: 450722110000, parentCode: 450722000000, name: "福旺镇"},
	{ code: 450722111000, parentCode: 450722000000, name: "寨圩镇"},
	{ code: 450722112000, parentCode: 450722000000, name: "乐民镇"},
	{ code: 450722113000, parentCode: 450722000000, name: "六硍镇"},
	{ code: 450722114000, parentCode: 450722000000, name: "平睦镇"},
	{ code: 450722115000, parentCode: 450722000000, name: "官垌镇"},
	{ code: 450800000000, parentCode: 450000000000, name: "贵港市"},
	{ code: 450802000000, parentCode: 450800000000, name: "港北区"},
	{ code: 450802001000, parentCode: 450802000000, name: "贵城街道"},
	{ code: 450802002000, parentCode: 450802000000, name: "港城街道"},
	{ code: 450802101000, parentCode: 450802000000, name: "大圩镇"},
	{ code: 450802102000, parentCode: 450802000000, name: "庆丰镇"},
	{ code: 450802103000, parentCode: 450802000000, name: "根竹镇"},
	{ code: 450802104000, parentCode: 450802000000, name: "武乐镇"},
	{ code: 450802200000, parentCode: 450802000000, name: "奇石乡"},
	{ code: 450802201000, parentCode: 450802000000, name: "中里乡"},
	{ code: 450803000000, parentCode: 450800000000, name: "港南区"},
	{ code: 450803001000, parentCode: 450803000000, name: "江南街道"},
	{ code: 450803002000, parentCode: 450803000000, name: "八塘街道"},
	{ code: 450803100000, parentCode: 450803000000, name: "桥圩镇"},
	{ code: 450803101000, parentCode: 450803000000, name: "木格镇"},
	{ code: 450803102000, parentCode: 450803000000, name: "木梓镇"},
	{ code: 450803103000, parentCode: 450803000000, name: "湛江镇"},
	{ code: 450803104000, parentCode: 450803000000, name: "东津镇"},
	{ code: 450803106000, parentCode: 450803000000, name: "新塘镇"},
	{ code: 450803107000, parentCode: 450803000000, name: "瓦塘镇"},
	{ code: 450804000000, parentCode: 450800000000, name: "覃塘区"},
	{ code: 450804001000, parentCode: 450804000000, name: "覃塘街道"},
	{ code: 450804101000, parentCode: 450804000000, name: "东龙镇"},
	{ code: 450804102000, parentCode: 450804000000, name: "三里镇"},
	{ code: 450804103000, parentCode: 450804000000, name: "黄练镇"},
	{ code: 450804104000, parentCode: 450804000000, name: "石卡镇"},
	{ code: 450804105000, parentCode: 450804000000, name: "五里镇"},
	{ code: 450804106000, parentCode: 450804000000, name: "樟木镇"},
	{ code: 450804107000, parentCode: 450804000000, name: "蒙公镇"},
	{ code: 450804200000, parentCode: 450804000000, name: "山北乡"},
	{ code: 450804204000, parentCode: 450804000000, name: "大岭乡"},
	{ code: 450821000000, parentCode: 450800000000, name: "平南县"},
	{ code: 450821001000, parentCode: 450821000000, name: "平南街道"},
	{ code: 450821002000, parentCode: 450821000000, name: "上渡街道"},
	{ code: 450821101000, parentCode: 450821000000, name: "平山镇"},
	{ code: 450821102000, parentCode: 450821000000, name: "寺面镇"},
	{ code: 450821103000, parentCode: 450821000000, name: "六陈镇"},
	{ code: 450821104000, parentCode: 450821000000, name: "大新镇"},
	{ code: 450821105000, parentCode: 450821000000, name: "大安镇"},
	{ code: 450821106000, parentCode: 450821000000, name: "武林镇"},
	{ code: 450821107000, parentCode: 450821000000, name: "大坡镇"},
	{ code: 450821108000, parentCode: 450821000000, name: "大洲镇"},
	{ code: 450821109000, parentCode: 450821000000, name: "镇隆镇"},
	{ code: 450821112000, parentCode: 450821000000, name: "安怀镇"},
	{ code: 450821113000, parentCode: 450821000000, name: "丹竹镇"},
	{ code: 450821114000, parentCode: 450821000000, name: "官成镇"},
	{ code: 450821115000, parentCode: 450821000000, name: "思旺镇"},
	{ code: 450821116000, parentCode: 450821000000, name: "大鹏镇"},
	{ code: 450821117000, parentCode: 450821000000, name: "同和镇"},
	{ code: 450821118000, parentCode: 450821000000, name: "东华镇"},
	{ code: 450821204000, parentCode: 450821000000, name: "思界乡"},
	{ code: 450821205000, parentCode: 450821000000, name: "国安瑶族乡"},
	{ code: 450821206000, parentCode: 450821000000, name: "马练瑶族乡"},
	{ code: 450881000000, parentCode: 450800000000, name: "桂平市"},
	{ code: 450881101000, parentCode: 450881000000, name: "木乐镇"},
	{ code: 450881102000, parentCode: 450881000000, name: "木圭镇"},
	{ code: 450881103000, parentCode: 450881000000, name: "石咀镇"},
	{ code: 450881104000, parentCode: 450881000000, name: "油麻镇"},
	{ code: 450881105000, parentCode: 450881000000, name: "社坡镇"},
	{ code: 450881106000, parentCode: 450881000000, name: "罗秀镇"},
	{ code: 450881107000, parentCode: 450881000000, name: "麻垌镇"},
	{ code: 450881108000, parentCode: 450881000000, name: "社步镇"},
	{ code: 450881109000, parentCode: 450881000000, name: "下湾镇"},
	{ code: 450881110000, parentCode: 450881000000, name: "木根镇"},
	{ code: 450881111000, parentCode: 450881000000, name: "中沙镇"},
	{ code: 450881113000, parentCode: 450881000000, name: "大洋镇"},
	{ code: 450881114000, parentCode: 450881000000, name: "大湾镇"},
	{ code: 450881115000, parentCode: 450881000000, name: "白沙镇"},
	{ code: 450881116000, parentCode: 450881000000, name: "石龙镇"},
	{ code: 450881117000, parentCode: 450881000000, name: "蒙圩镇"},
	{ code: 450881118000, parentCode: 450881000000, name: "西山镇"},
	{ code: 450881119000, parentCode: 450881000000, name: "南木镇"},
	{ code: 450881120000, parentCode: 450881000000, name: "江口镇"},
	{ code: 450881121000, parentCode: 450881000000, name: "金田镇"},
	{ code: 450881122000, parentCode: 450881000000, name: "紫荆镇"},
	{ code: 450881200000, parentCode: 450881000000, name: "马皮乡"},
	{ code: 450881202000, parentCode: 450881000000, name: "寻旺乡"},
	{ code: 450881203000, parentCode: 450881000000, name: "罗播乡"},
	{ code: 450881204000, parentCode: 450881000000, name: "厚禄乡"},
	{ code: 450881206000, parentCode: 450881000000, name: "垌心乡"},
	{ code: 450900000000, parentCode: 450000000000, name: "玉林市"},
	{ code: 450902000000, parentCode: 450900000000, name: "玉州区"},
	{ code: 450902001000, parentCode: 450902000000, name: "玉城街道"},
	{ code: 450902002000, parentCode: 450902000000, name: "南江街道"},
	{ code: 450902003000, parentCode: 450902000000, name: "城西街道"},
	{ code: 450902004000, parentCode: 450902000000, name: "城北街道"},
	{ code: 450902005000, parentCode: 450902000000, name: "名山街道"},
	{ code: 450902100000, parentCode: 450902000000, name: "大塘镇"},
	{ code: 450902105000, parentCode: 450902000000, name: "茂林镇"},
	{ code: 450902106000, parentCode: 450902000000, name: "仁东镇"},
	{ code: 450902113000, parentCode: 450902000000, name: "仁厚镇"},
	{ code: 450903000000, parentCode: 450900000000, name: "福绵区"},
	{ code: 450903107000, parentCode: 450903000000, name: "福绵镇"},
	{ code: 450903108000, parentCode: 450903000000, name: "成均镇"},
	{ code: 450903109000, parentCode: 450903000000, name: "樟木镇"},
	{ code: 450903110000, parentCode: 450903000000, name: "新桥镇"},
	{ code: 450903111000, parentCode: 450903000000, name: "沙田镇"},
	{ code: 450903112000, parentCode: 450903000000, name: "石和镇"},
	{ code: 450921000000, parentCode: 450900000000, name: "容县"},
	{ code: 450921100000, parentCode: 450921000000, name: "容州镇"},
	{ code: 450921102000, parentCode: 450921000000, name: "杨梅镇"},
	{ code: 450921103000, parentCode: 450921000000, name: "灵山镇"},
	{ code: 450921104000, parentCode: 450921000000, name: "六王镇"},
	{ code: 450921105000, parentCode: 450921000000, name: "黎村镇"},
	{ code: 450921106000, parentCode: 450921000000, name: "杨村镇"},
	{ code: 450921107000, parentCode: 450921000000, name: "县底镇"},
	{ code: 450921108000, parentCode: 450921000000, name: "自良镇"},
	{ code: 450921109000, parentCode: 450921000000, name: "松山镇"},
	{ code: 450921110000, parentCode: 450921000000, name: "罗江镇"},
	{ code: 450921111000, parentCode: 450921000000, name: "石头镇"},
	{ code: 450921112000, parentCode: 450921000000, name: "石寨镇"},
	{ code: 450921113000, parentCode: 450921000000, name: "十里镇"},
	{ code: 450921114000, parentCode: 450921000000, name: "容西镇"},
	{ code: 450921115000, parentCode: 450921000000, name: "浪水镇"},
	{ code: 450922000000, parentCode: 450900000000, name: "陆川县"},
	{ code: 450922101000, parentCode: 450922000000, name: "温泉镇"},
	{ code: 450922102000, parentCode: 450922000000, name: "米场镇"},
	{ code: 450922103000, parentCode: 450922000000, name: "马坡镇"},
	{ code: 450922104000, parentCode: 450922000000, name: "珊罗镇"},
	{ code: 450922105000, parentCode: 450922000000, name: "平乐镇"},
	{ code: 450922106000, parentCode: 450922000000, name: "沙坡镇"},
	{ code: 450922107000, parentCode: 450922000000, name: "大桥镇"},
	{ code: 450922108000, parentCode: 450922000000, name: "乌石镇"},
	{ code: 450922109000, parentCode: 450922000000, name: "良田镇"},
	{ code: 450922110000, parentCode: 450922000000, name: "清湖镇"},
	{ code: 450922111000, parentCode: 450922000000, name: "古城镇"},
	{ code: 450922112000, parentCode: 450922000000, name: "沙湖镇"},
	{ code: 450922113000, parentCode: 450922000000, name: "横山镇"},
	{ code: 450922114000, parentCode: 450922000000, name: "滩面镇"},
	{ code: 450923000000, parentCode: 450900000000, name: "博白县"},
	{ code: 450923100000, parentCode: 450923000000, name: "博白镇"},
	{ code: 450923102000, parentCode: 450923000000, name: "双凤镇"},
	{ code: 450923105000, parentCode: 450923000000, name: "顿谷镇"},
	{ code: 450923106000, parentCode: 450923000000, name: "水鸣镇"},
	{ code: 450923107000, parentCode: 450923000000, name: "那林镇"},
	{ code: 450923108000, parentCode: 450923000000, name: "江宁镇"},
	{ code: 450923109000, parentCode: 450923000000, name: "三滩镇"},
	{ code: 450923110000, parentCode: 450923000000, name: "黄凌镇"},
	{ code: 450923111000, parentCode: 450923000000, name: "亚山镇"},
	{ code: 450923112000, parentCode: 450923000000, name: "旺茂镇"},
	{ code: 450923114000, parentCode: 450923000000, name: "东平镇"},
	{ code: 450923115000, parentCode: 450923000000, name: "沙河镇"},
	{ code: 450923116000, parentCode: 450923000000, name: "菱角镇"},
	{ code: 450923117000, parentCode: 450923000000, name: "新田镇"},
	{ code: 450923118000, parentCode: 450923000000, name: "凤山镇"},
	{ code: 450923119000, parentCode: 450923000000, name: "宁潭镇"},
	{ code: 450923120000, parentCode: 450923000000, name: "文地镇"},
	{ code: 450923121000, parentCode: 450923000000, name: "英桥镇"},
	{ code: 450923122000, parentCode: 450923000000, name: "那卜镇"},
	{ code: 450923123000, parentCode: 450923000000, name: "大垌镇"},
	{ code: 450923124000, parentCode: 450923000000, name: "沙陂镇"},
	{ code: 450923125000, parentCode: 450923000000, name: "双旺镇"},
	{ code: 450923126000, parentCode: 450923000000, name: "松旺镇"},
	{ code: 450923127000, parentCode: 450923000000, name: "龙潭镇"},
	{ code: 450923128000, parentCode: 450923000000, name: "大坝镇"},
	{ code: 450923129000, parentCode: 450923000000, name: "永安镇"},
	{ code: 450923130000, parentCode: 450923000000, name: "径口镇"},
	{ code: 450923131000, parentCode: 450923000000, name: "浪平镇"},
	{ code: 450924000000, parentCode: 450900000000, name: "兴业县"},
	{ code: 450924100000, parentCode: 450924000000, name: "石南镇"},
	{ code: 450924101000, parentCode: 450924000000, name: "大平山镇"},
	{ code: 450924102000, parentCode: 450924000000, name: "葵阳镇"},
	{ code: 450924103000, parentCode: 450924000000, name: "城隍镇"},
	{ code: 450924104000, parentCode: 450924000000, name: "山心镇"},
	{ code: 450924105000, parentCode: 450924000000, name: "沙塘镇"},
	{ code: 450924106000, parentCode: 450924000000, name: "蒲塘镇"},
	{ code: 450924107000, parentCode: 450924000000, name: "北市镇"},
	{ code: 450924108000, parentCode: 450924000000, name: "龙安镇"},
	{ code: 450924109000, parentCode: 450924000000, name: "高峰镇"},
	{ code: 450924110000, parentCode: 450924000000, name: "小平山镇"},
	{ code: 450924111000, parentCode: 450924000000, name: "卖酒镇"},
	{ code: 450924112000, parentCode: 450924000000, name: "洛阳镇"},
	{ code: 450981000000, parentCode: 450900000000, name: "北流市"},
	{ code: 450981001000, parentCode: 450981000000, name: "陵城街道"},
	{ code: 450981002000, parentCode: 450981000000, name: "城南街道"},
	{ code: 450981003000, parentCode: 450981000000, name: "城北街道"},
	{ code: 450981100000, parentCode: 450981000000, name: "北流镇"},
	{ code: 450981101000, parentCode: 450981000000, name: "新荣镇"},
	{ code: 450981102000, parentCode: 450981000000, name: "民安镇"},
	{ code: 450981103000, parentCode: 450981000000, name: "山围镇"},
	{ code: 450981104000, parentCode: 450981000000, name: "民乐镇"},
	{ code: 450981105000, parentCode: 450981000000, name: "西埌镇"},
	{ code: 450981106000, parentCode: 450981000000, name: "新圩镇"},
	{ code: 450981107000, parentCode: 450981000000, name: "大里镇"},
	{ code: 450981108000, parentCode: 450981000000, name: "塘岸镇"},
	{ code: 450981109000, parentCode: 450981000000, name: "清水口镇"},
	{ code: 450981110000, parentCode: 450981000000, name: "隆盛镇"},
	{ code: 450981111000, parentCode: 450981000000, name: "大坡外镇"},
	{ code: 450981112000, parentCode: 450981000000, name: "六麻镇"},
	{ code: 450981113000, parentCode: 450981000000, name: "新丰镇"},
	{ code: 450981114000, parentCode: 450981000000, name: "沙垌镇"},
	{ code: 450981115000, parentCode: 450981000000, name: "平政镇"},
	{ code: 450981116000, parentCode: 450981000000, name: "白马镇"},
	{ code: 450981117000, parentCode: 450981000000, name: "大伦镇"},
	{ code: 450981118000, parentCode: 450981000000, name: "扶新镇"},
	{ code: 450981119000, parentCode: 450981000000, name: "六靖镇"},
	{ code: 450981120000, parentCode: 450981000000, name: "石窝镇"},
	{ code: 450981121000, parentCode: 450981000000, name: "清湾镇"},
	{ code: 451000000000, parentCode: 450000000000, name: "百色市"},
	{ code: 451002000000, parentCode: 451000000000, name: "右江区"},
	{ code: 451002001000, parentCode: 451002000000, name: "百城街道"},
	{ code: 451002002000, parentCode: 451002000000, name: "龙景街道"},
	{ code: 451002101000, parentCode: 451002000000, name: "阳圩镇"},
	{ code: 451002102000, parentCode: 451002000000, name: "四塘镇"},
	{ code: 451002103000, parentCode: 451002000000, name: "龙川镇"},
	{ code: 451002104000, parentCode: 451002000000, name: "永乐镇"},
	{ code: 451002202000, parentCode: 451002000000, name: "汪甸瑶族乡"},
	{ code: 451002203000, parentCode: 451002000000, name: "大楞乡"},
	{ code: 451002204000, parentCode: 451002000000, name: "泮水乡"},
	{ code: 451003000000, parentCode: 451000000000, name: "田阳区"},
	{ code: 451003100000, parentCode: 451003000000, name: "田州镇"},
	{ code: 451003101000, parentCode: 451003000000, name: "那坡镇"},
	{ code: 451003102000, parentCode: 451003000000, name: "坡洪镇"},
	{ code: 451003103000, parentCode: 451003000000, name: "那满镇"},
	{ code: 451003104000, parentCode: 451003000000, name: "百育镇"},
	{ code: 451003105000, parentCode: 451003000000, name: "玉凤镇"},
	{ code: 451003106000, parentCode: 451003000000, name: "头塘镇"},
	{ code: 451003107000, parentCode: 451003000000, name: "五村镇"},
	{ code: 451003108000, parentCode: 451003000000, name: "洞靖镇"},
	{ code: 451003204000, parentCode: 451003000000, name: "巴别乡"},
	{ code: 451022000000, parentCode: 451000000000, name: "田东县"},
	{ code: 451022100000, parentCode: 451022000000, name: "平马镇"},
	{ code: 451022101000, parentCode: 451022000000, name: "祥周镇"},
	{ code: 451022103000, parentCode: 451022000000, name: "林逢镇"},
	{ code: 451022105000, parentCode: 451022000000, name: "思林镇"},
	{ code: 451022106000, parentCode: 451022000000, name: "印茶镇"},
	{ code: 451022107000, parentCode: 451022000000, name: "江城镇"},
	{ code: 451022108000, parentCode: 451022000000, name: "朔良镇"},
	{ code: 451022109000, parentCode: 451022000000, name: "义圩镇"},
	{ code: 451022110000, parentCode: 451022000000, name: "那拔镇"},
	{ code: 451022201000, parentCode: 451022000000, name: "作登瑶族乡"},
	{ code: 451024000000, parentCode: 451000000000, name: "德保县"},
	{ code: 451024100000, parentCode: 451024000000, name: "城关镇"},
	{ code: 451024101000, parentCode: 451024000000, name: "足荣镇"},
	{ code: 451024102000, parentCode: 451024000000, name: "隆桑镇"},
	{ code: 451024103000, parentCode: 451024000000, name: "敬德镇"},
	{ code: 451024104000, parentCode: 451024000000, name: "马隘镇"},
	{ code: 451024105000, parentCode: 451024000000, name: "东凌镇"},
	{ code: 451024106000, parentCode: 451024000000, name: "那甲镇"},
	{ code: 451024200000, parentCode: 451024000000, name: "都安乡"},
	{ code: 451024203000, parentCode: 451024000000, name: "荣华乡"},
	{ code: 451024204000, parentCode: 451024000000, name: "燕峒乡"},
	{ code: 451024205000, parentCode: 451024000000, name: "龙光乡"},
	{ code: 451024206000, parentCode: 451024000000, name: "巴头乡"},
	{ code: 451024450000, parentCode: 451024000000, name: "德保铝业"},
	{ code: 451026000000, parentCode: 451000000000, name: "那坡县"},
	{ code: 451026100000, parentCode: 451026000000, name: "城厢镇"},
	{ code: 451026101000, parentCode: 451026000000, name: "平孟镇"},
	{ code: 451026102000, parentCode: 451026000000, name: "龙合镇"},
	{ code: 451026200000, parentCode: 451026000000, name: "坡荷乡"},
	{ code: 451026203000, parentCode: 451026000000, name: "德隆乡"},
	{ code: 451026204000, parentCode: 451026000000, name: "百合乡"},
	{ code: 451026205000, parentCode: 451026000000, name: "百南乡"},
	{ code: 451026207000, parentCode: 451026000000, name: "百省乡"},
	{ code: 451026208000, parentCode: 451026000000, name: "百都乡"},
	{ code: 451027000000, parentCode: 451000000000, name: "凌云县"},
	{ code: 451027100000, parentCode: 451027000000, name: "泗城镇"},
	{ code: 451027101000, parentCode: 451027000000, name: "逻楼镇"},
	{ code: 451027102000, parentCode: 451027000000, name: "加尤镇"},
	{ code: 451027103000, parentCode: 451027000000, name: "下甲镇"},
	{ code: 451027201000, parentCode: 451027000000, name: "伶站瑶族乡"},
	{ code: 451027202000, parentCode: 451027000000, name: "朝里瑶族乡"},
	{ code: 451027203000, parentCode: 451027000000, name: "沙里瑶族乡"},
	{ code: 451027205000, parentCode: 451027000000, name: "玉洪瑶族乡"},
	{ code: 451028000000, parentCode: 451000000000, name: "乐业县"},
	{ code: 451028100000, parentCode: 451028000000, name: "同乐镇"},
	{ code: 451028101000, parentCode: 451028000000, name: "甘田镇"},
	{ code: 451028102000, parentCode: 451028000000, name: "新化镇"},
	{ code: 451028103000, parentCode: 451028000000, name: "花坪镇"},
	{ code: 451028201000, parentCode: 451028000000, name: "逻沙乡"},
	{ code: 451028203000, parentCode: 451028000000, name: "逻西乡"},
	{ code: 451028204000, parentCode: 451028000000, name: "幼平乡"},
	{ code: 451028205000, parentCode: 451028000000, name: "雅长乡"},
	{ code: 451029000000, parentCode: 451000000000, name: "田林县"},
	{ code: 451029100000, parentCode: 451029000000, name: "乐里镇"},
	{ code: 451029101000, parentCode: 451029000000, name: "旧州镇"},
	{ code: 451029102000, parentCode: 451029000000, name: "定安镇"},
	{ code: 451029103000, parentCode: 451029000000, name: "六隆镇"},
	{ code: 451029104000, parentCode: 451029000000, name: "浪平镇"},
	{ code: 451029200000, parentCode: 451029000000, name: "潞城瑶族乡"},
	{ code: 451029201000, parentCode: 451029000000, name: "利周瑶族乡"},
	{ code: 451029202000, parentCode: 451029000000, name: "平塘乡"},
	{ code: 451029204000, parentCode: 451029000000, name: "八桂瑶族乡"},
	{ code: 451029205000, parentCode: 451029000000, name: "八渡瑶族乡"},
	{ code: 451029206000, parentCode: 451029000000, name: "那比乡"},
	{ code: 451029207000, parentCode: 451029000000, name: "高龙乡"},
	{ code: 451029208000, parentCode: 451029000000, name: "百乐乡"},
	{ code: 451029209000, parentCode: 451029000000, name: "者苗乡"},
	{ code: 451030000000, parentCode: 451000000000, name: "西林县"},
	{ code: 451030100000, parentCode: 451030000000, name: "八达镇"},
	{ code: 451030101000, parentCode: 451030000000, name: "古障镇"},
	{ code: 451030102000, parentCode: 451030000000, name: "那劳镇"},
	{ code: 451030103000, parentCode: 451030000000, name: "马蚌镇"},
	{ code: 451030203000, parentCode: 451030000000, name: "普合苗族乡"},
	{ code: 451030204000, parentCode: 451030000000, name: "西平乡"},
	{ code: 451030206000, parentCode: 451030000000, name: "那佐苗族乡"},
	{ code: 451030208000, parentCode: 451030000000, name: "足别瑶族苗族乡"},
	{ code: 451031000000, parentCode: 451000000000, name: "隆林各族自治县"},
	{ code: 451031100000, parentCode: 451031000000, name: "新州镇"},
	{ code: 451031101000, parentCode: 451031000000, name: "桠杈镇"},
	{ code: 451031102000, parentCode: 451031000000, name: "天生桥镇"},
	{ code: 451031103000, parentCode: 451031000000, name: "平班镇"},
	{ code: 451031104000, parentCode: 451031000000, name: "德峨镇"},
	{ code: 451031105000, parentCode: 451031000000, name: "隆或镇"},
	{ code: 451031200000, parentCode: 451031000000, name: "沙梨乡"},
	{ code: 451031203000, parentCode: 451031000000, name: "者保乡"},
	{ code: 451031204000, parentCode: 451031000000, name: "者浪乡"},
	{ code: 451031205000, parentCode: 451031000000, name: "革步乡"},
	{ code: 451031206000, parentCode: 451031000000, name: "金钟山乡"},
	{ code: 451031208000, parentCode: 451031000000, name: "猪场乡"},
	{ code: 451031210000, parentCode: 451031000000, name: "蛇场乡"},
	{ code: 451031211000, parentCode: 451031000000, name: "克长乡"},
	{ code: 451031213000, parentCode: 451031000000, name: "岩茶乡"},
	{ code: 451031215000, parentCode: 451031000000, name: "介廷乡"},
	{ code: 451081000000, parentCode: 451000000000, name: "靖西市"},
	{ code: 451081100000, parentCode: 451081000000, name: "新靖镇"},
	{ code: 451081101000, parentCode: 451081000000, name: "化峒镇"},
	{ code: 451081102000, parentCode: 451081000000, name: "湖润镇"},
	{ code: 451081103000, parentCode: 451081000000, name: "安德镇"},
	{ code: 451081104000, parentCode: 451081000000, name: "龙临镇"},
	{ code: 451081105000, parentCode: 451081000000, name: "渠洋镇"},
	{ code: 451081106000, parentCode: 451081000000, name: "岳圩镇"},
	{ code: 451081107000, parentCode: 451081000000, name: "龙邦镇"},
	{ code: 451081108000, parentCode: 451081000000, name: "禄峒镇"},
	{ code: 451081109000, parentCode: 451081000000, name: "武平镇"},
	{ code: 451081110000, parentCode: 451081000000, name: "地州镇"},
	{ code: 451081200000, parentCode: 451081000000, name: "同德乡"},
	{ code: 451081201000, parentCode: 451081000000, name: "壬庄乡"},
	{ code: 451081202000, parentCode: 451081000000, name: "安宁乡"},
	{ code: 451081206000, parentCode: 451081000000, name: "南坡乡"},
	{ code: 451081207000, parentCode: 451081000000, name: "吞盘乡"},
	{ code: 451081209000, parentCode: 451081000000, name: "果乐乡"},
	{ code: 451081211000, parentCode: 451081000000, name: "新甲乡"},
	{ code: 451081215000, parentCode: 451081000000, name: "魁圩乡"},
	{ code: 451082000000, parentCode: 451000000000, name: "平果市"},
	{ code: 451082100000, parentCode: 451082000000, name: "马头镇"},
	{ code: 451082101000, parentCode: 451082000000, name: "新安镇"},
	{ code: 451082102000, parentCode: 451082000000, name: "果化镇"},
	{ code: 451082103000, parentCode: 451082000000, name: "太平镇"},
	{ code: 451082104000, parentCode: 451082000000, name: "坡造镇"},
	{ code: 451082105000, parentCode: 451082000000, name: "四塘镇"},
	{ code: 451082106000, parentCode: 451082000000, name: "旧城镇"},
	{ code: 451082107000, parentCode: 451082000000, name: "榜圩镇"},
	{ code: 451082108000, parentCode: 451082000000, name: "凤梧镇"},
	{ code: 451082202000, parentCode: 451082000000, name: "海城乡"},
	{ code: 451082205000, parentCode: 451082000000, name: "黎明乡"},
	{ code: 451082206000, parentCode: 451082000000, name: "同老乡"},
	{ code: 451082450000, parentCode: 451082000000, name: "平果铝"},
	{ code: 451100000000, parentCode: 450000000000, name: "贺州市"},
	{ code: 451102000000, parentCode: 451100000000, name: "八步区"},
	{ code: 451102001000, parentCode: 451102000000, name: "八步街道"},
	{ code: 451102002000, parentCode: 451102000000, name: "城东街道"},
	{ code: 451102003000, parentCode: 451102000000, name: "江南街道"},
	{ code: 451102101000, parentCode: 451102000000, name: "贺街镇"},
	{ code: 451102102000, parentCode: 451102000000, name: "步头镇"},
	{ code: 451102103000, parentCode: 451102000000, name: "莲塘镇"},
	{ code: 451102104000, parentCode: 451102000000, name: "大宁镇"},
	{ code: 451102105000, parentCode: 451102000000, name: "南乡镇"},
	{ code: 451102106000, parentCode: 451102000000, name: "桂岭镇"},
	{ code: 451102107000, parentCode: 451102000000, name: "开山镇"},
	{ code: 451102109000, parentCode: 451102000000, name: "里松镇"},
	{ code: 451102114000, parentCode: 451102000000, name: "信都镇"},
	{ code: 451102115000, parentCode: 451102000000, name: "灵峰镇"},
	{ code: 451102116000, parentCode: 451102000000, name: "仁义镇"},
	{ code: 451102117000, parentCode: 451102000000, name: "铺门镇"},
	{ code: 451102200000, parentCode: 451102000000, name: "黄洞瑶族乡"},
	{ code: 451103000000, parentCode: 451100000000, name: "平桂区"},
	{ code: 451103001000, parentCode: 451103000000, name: "西湾街道"},
	{ code: 451103101000, parentCode: 451103000000, name: "黄田镇"},
	{ code: 451103102000, parentCode: 451103000000, name: "鹅塘镇"},
	{ code: 451103103000, parentCode: 451103000000, name: "沙田镇"},
	{ code: 451103104000, parentCode: 451103000000, name: "公会镇"},
	{ code: 451103105000, parentCode: 451103000000, name: "水口镇"},
	{ code: 451103106000, parentCode: 451103000000, name: "望高镇"},
	{ code: 451103107000, parentCode: 451103000000, name: "羊头镇"},
	{ code: 451103201000, parentCode: 451103000000, name: "大平瑶族乡"},
	{ code: 451121000000, parentCode: 451100000000, name: "昭平县"},
	{ code: 451121100000, parentCode: 451121000000, name: "昭平镇"},
	{ code: 451121101000, parentCode: 451121000000, name: "文竹镇"},
	{ code: 451121102000, parentCode: 451121000000, name: "黄姚镇"},
	{ code: 451121103000, parentCode: 451121000000, name: "富罗镇"},
	{ code: 451121104000, parentCode: 451121000000, name: "北陀镇"},
	{ code: 451121105000, parentCode: 451121000000, name: "马江镇"},
	{ code: 451121107000, parentCode: 451121000000, name: "五将镇"},
	{ code: 451121108000, parentCode: 451121000000, name: "走马镇"},
	{ code: 451121109000, parentCode: 451121000000, name: "樟木林镇"},
	{ code: 451121201000, parentCode: 451121000000, name: "仙回瑶族乡"},
	{ code: 451121206000, parentCode: 451121000000, name: "凤凰乡"},
	{ code: 451121208000, parentCode: 451121000000, name: "木格乡"},
	{ code: 451122000000, parentCode: 451100000000, name: "钟山县"},
	{ code: 451122100000, parentCode: 451122000000, name: "钟山镇"},
	{ code: 451122104000, parentCode: 451122000000, name: "回龙镇"},
	{ code: 451122105000, parentCode: 451122000000, name: "石龙镇"},
	{ code: 451122106000, parentCode: 451122000000, name: "凤翔镇"},
	{ code: 451122107000, parentCode: 451122000000, name: "珊瑚镇"},
	{ code: 451122108000, parentCode: 451122000000, name: "同古镇"},
	{ code: 451122109000, parentCode: 451122000000, name: "公安镇"},
	{ code: 451122111000, parentCode: 451122000000, name: "清塘镇"},
	{ code: 451122112000, parentCode: 451122000000, name: "燕塘镇"},
	{ code: 451122113000, parentCode: 451122000000, name: "红花镇"},
	{ code: 451122200000, parentCode: 451122000000, name: "花山瑶族乡"},
	{ code: 451122201000, parentCode: 451122000000, name: "两安瑶族乡"},
	{ code: 451123000000, parentCode: 451100000000, name: "富川瑶族自治县"},
	{ code: 451123100000, parentCode: 451123000000, name: "富阳镇"},
	{ code: 451123101000, parentCode: 451123000000, name: "白沙镇"},
	{ code: 451123102000, parentCode: 451123000000, name: "莲山镇"},
	{ code: 451123103000, parentCode: 451123000000, name: "古城镇"},
	{ code: 451123104000, parentCode: 451123000000, name: "福利镇"},
	{ code: 451123105000, parentCode: 451123000000, name: "麦岭镇"},
	{ code: 451123106000, parentCode: 451123000000, name: "葛坡镇"},
	{ code: 451123107000, parentCode: 451123000000, name: "城北镇"},
	{ code: 451123108000, parentCode: 451123000000, name: "朝东镇"},
	{ code: 451123200000, parentCode: 451123000000, name: "新华乡"},
	{ code: 451123201000, parentCode: 451123000000, name: "石家乡"},
	{ code: 451123203000, parentCode: 451123000000, name: "柳家乡"},
	{ code: 451200000000, parentCode: 450000000000, name: "河池市"},
	{ code: 451202000000, parentCode: 451200000000, name: "金城江区"},
	{ code: 451202001000, parentCode: 451202000000, name: "金城江街道"},
	{ code: 451202101000, parentCode: 451202000000, name: "东江镇"},
	{ code: 451202102000, parentCode: 451202000000, name: "六圩镇"},
	{ code: 451202103000, parentCode: 451202000000, name: "六甲镇"},
	{ code: 451202104000, parentCode: 451202000000, name: "河池镇"},
	{ code: 451202105000, parentCode: 451202000000, name: "拔贡镇"},
	{ code: 451202106000, parentCode: 451202000000, name: "九圩镇"},
	{ code: 451202107000, parentCode: 451202000000, name: "五圩镇"},
	{ code: 451202200000, parentCode: 451202000000, name: "白土乡"},
	{ code: 451202203000, parentCode: 451202000000, name: "侧岭乡"},
	{ code: 451202204000, parentCode: 451202000000, name: "保平乡"},
	{ code: 451202205000, parentCode: 451202000000, name: "长老乡"},
	{ code: 451203000000, parentCode: 451200000000, name: "宜州区"},
	{ code: 451203100000, parentCode: 451203000000, name: "庆远镇"},
	{ code: 451203101000, parentCode: 451203000000, name: "三岔镇"},
	{ code: 451203102000, parentCode: 451203000000, name: "洛西镇"},
	{ code: 451203103000, parentCode: 451203000000, name: "怀远镇"},
	{ code: 451203104000, parentCode: 451203000000, name: "德胜镇"},
	{ code: 451203105000, parentCode: 451203000000, name: "石别镇"},
	{ code: 451203106000, parentCode: 451203000000, name: "北山镇"},
	{ code: 451203107000, parentCode: 451203000000, name: "刘三姐镇"},
	{ code: 451203108000, parentCode: 451203000000, name: "洛东镇"},
	{ code: 451203200000, parentCode: 451203000000, name: "祥贝乡"},
	{ code: 451203203000, parentCode: 451203000000, name: "屏南乡"},
	{ code: 451203207000, parentCode: 451203000000, name: "福龙瑶族乡"},
	{ code: 451203208000, parentCode: 451203000000, name: "北牙瑶族乡"},
	{ code: 451203210000, parentCode: 451203000000, name: "同德乡"},
	{ code: 451203211000, parentCode: 451203000000, name: "安马乡"},
	{ code: 451203213000, parentCode: 451203000000, name: "龙头乡"},
	{ code: 451221000000, parentCode: 451200000000, name: "南丹县"},
	{ code: 451221100000, parentCode: 451221000000, name: "城关镇"},
	{ code: 451221101000, parentCode: 451221000000, name: "大厂镇"},
	{ code: 451221102000, parentCode: 451221000000, name: "车河镇"},
	{ code: 451221103000, parentCode: 451221000000, name: "芒场镇"},
	{ code: 451221104000, parentCode: 451221000000, name: "六寨镇"},
	{ code: 451221105000, parentCode: 451221000000, name: "月里镇"},
	{ code: 451221106000, parentCode: 451221000000, name: "吾隘镇"},
	{ code: 451221107000, parentCode: 451221000000, name: "罗富镇"},
	{ code: 451221202000, parentCode: 451221000000, name: "中堡苗族乡"},
	{ code: 451221203000, parentCode: 451221000000, name: "八圩瑶族乡"},
	{ code: 451221204000, parentCode: 451221000000, name: "里湖瑶族乡"},
	{ code: 451222000000, parentCode: 451200000000, name: "天峨县"},
	{ code: 451222100000, parentCode: 451222000000, name: "六排镇"},
	{ code: 451222101000, parentCode: 451222000000, name: "向阳镇"},
	{ code: 451222200000, parentCode: 451222000000, name: "岜暮乡"},
	{ code: 451222201000, parentCode: 451222000000, name: "八腊瑶族乡"},
	{ code: 451222203000, parentCode: 451222000000, name: "纳直乡"},
	{ code: 451222204000, parentCode: 451222000000, name: "更新乡"},
	{ code: 451222206000, parentCode: 451222000000, name: "下老乡"},
	{ code: 451222207000, parentCode: 451222000000, name: "坡结乡"},
	{ code: 451222208000, parentCode: 451222000000, name: "三堡乡"},
	{ code: 451223000000, parentCode: 451200000000, name: "凤山县"},
	{ code: 451223001000, parentCode: 451223000000, name: "思源街道"},
	{ code: 451223100000, parentCode: 451223000000, name: "凤城镇"},
	{ code: 451223101000, parentCode: 451223000000, name: "长洲镇"},
	{ code: 451223102000, parentCode: 451223000000, name: "三门海镇"},
	{ code: 451223201000, parentCode: 451223000000, name: "砦牙乡"},
	{ code: 451223203000, parentCode: 451223000000, name: "乔音乡"},
	{ code: 451223205000, parentCode: 451223000000, name: "金牙瑶族乡"},
	{ code: 451223207000, parentCode: 451223000000, name: "中亭乡"},
	{ code: 451223208000, parentCode: 451223000000, name: "平乐瑶族乡"},
	{ code: 451223209000, parentCode: 451223000000, name: "江洲瑶族乡"},
	{ code: 451224000000, parentCode: 451200000000, name: "东兰县"},
	{ code: 451224100000, parentCode: 451224000000, name: "东兰镇"},
	{ code: 451224101000, parentCode: 451224000000, name: "隘洞镇"},
	{ code: 451224102000, parentCode: 451224000000, name: "长乐镇"},
	{ code: 451224103000, parentCode: 451224000000, name: "三石镇"},
	{ code: 451224104000, parentCode: 451224000000, name: "武篆镇"},
	{ code: 451224105000, parentCode: 451224000000, name: "长江镇"},
	{ code: 451224200000, parentCode: 451224000000, name: "泗孟乡"},
	{ code: 451224201000, parentCode: 451224000000, name: "兰木乡"},
	{ code: 451224203000, parentCode: 451224000000, name: "巴畴乡"},
	{ code: 451224204000, parentCode: 451224000000, name: "金谷乡"},
	{ code: 451224205000, parentCode: 451224000000, name: "三弄瑶族乡"},
	{ code: 451224206000, parentCode: 451224000000, name: "大同乡"},
	{ code: 451224207000, parentCode: 451224000000, name: "花香乡"},
	{ code: 451224208000, parentCode: 451224000000, name: "切学乡"},
	{ code: 451225000000, parentCode: 451200000000, name: "罗城仫佬族自治县"},
	{ code: 451225100000, parentCode: 451225000000, name: "东门镇"},
	{ code: 451225101000, parentCode: 451225000000, name: "龙岸镇"},
	{ code: 451225102000, parentCode: 451225000000, name: "黄金镇"},
	{ code: 451225103000, parentCode: 451225000000, name: "小长安镇"},
	{ code: 451225105000, parentCode: 451225000000, name: "四把镇"},
	{ code: 451225106000, parentCode: 451225000000, name: "天河镇"},
	{ code: 451225107000, parentCode: 451225000000, name: "怀群镇"},
	{ code: 451225200000, parentCode: 451225000000, name: "宝坛乡"},
	{ code: 451225202000, parentCode: 451225000000, name: "乔善乡"},
	{ code: 451225203000, parentCode: 451225000000, name: "纳翁乡"},
	{ code: 451225204000, parentCode: 451225000000, name: "兼爱乡"},
	{ code: 451226000000, parentCode: 451200000000, name: "环江毛南族自治县"},
	{ code: 451226001000, parentCode: 451226000000, name: "城西街道"},
	{ code: 451226100000, parentCode: 451226000000, name: "思恩镇"},
	{ code: 451226101000, parentCode: 451226000000, name: "水源镇"},
	{ code: 451226102000, parentCode: 451226000000, name: "洛阳镇"},
	{ code: 451226103000, parentCode: 451226000000, name: "川山镇"},
	{ code: 451226104000, parentCode: 451226000000, name: "明伦镇"},
	{ code: 451226105000, parentCode: 451226000000, name: "东兴镇"},
	{ code: 451226200000, parentCode: 451226000000, name: "大才乡"},
	{ code: 451226202000, parentCode: 451226000000, name: "下南乡"},
	{ code: 451226204000, parentCode: 451226000000, name: "大安乡"},
	{ code: 451226205000, parentCode: 451226000000, name: "长美乡"},
	{ code: 451226206000, parentCode: 451226000000, name: "龙岩乡"},
	{ code: 451226207000, parentCode: 451226000000, name: "驯乐苗族乡"},
	{ code: 451227000000, parentCode: 451200000000, name: "巴马瑶族自治县"},
	{ code: 451227100000, parentCode: 451227000000, name: "巴马镇"},
	{ code: 451227101000, parentCode: 451227000000, name: "甲篆镇"},
	{ code: 451227102000, parentCode: 451227000000, name: "燕洞镇"},
	{ code: 451227202000, parentCode: 451227000000, name: "那社乡"},
	{ code: 451227203000, parentCode: 451227000000, name: "所略乡"},
	{ code: 451227205000, parentCode: 451227000000, name: "西山乡"},
	{ code: 451227207000, parentCode: 451227000000, name: "东山乡"},
	{ code: 451227208000, parentCode: 451227000000, name: "凤凰乡"},
	{ code: 451227209000, parentCode: 451227000000, name: "百林乡"},
	{ code: 451227210000, parentCode: 451227000000, name: "那桃乡"},
	{ code: 451228000000, parentCode: 451200000000, name: "都安瑶族自治县"},
	{ code: 451228100000, parentCode: 451228000000, name: "安阳镇"},
	{ code: 451228101000, parentCode: 451228000000, name: "高岭镇"},
	{ code: 451228102000, parentCode: 451228000000, name: "地苏镇"},
	{ code: 451228103000, parentCode: 451228000000, name: "下坳镇"},
	{ code: 451228104000, parentCode: 451228000000, name: "拉烈镇"},
	{ code: 451228105000, parentCode: 451228000000, name: "百旺镇"},
	{ code: 451228106000, parentCode: 451228000000, name: "澄江镇"},
	{ code: 451228107000, parentCode: 451228000000, name: "大兴镇"},
	{ code: 451228108000, parentCode: 451228000000, name: "拉仁镇"},
	{ code: 451228109000, parentCode: 451228000000, name: "永安镇"},
	{ code: 451228204000, parentCode: 451228000000, name: "东庙乡"},
	{ code: 451228208000, parentCode: 451228000000, name: "隆福乡"},
	{ code: 451228209000, parentCode: 451228000000, name: "保安乡"},
	{ code: 451228210000, parentCode: 451228000000, name: "板岭乡"},
	{ code: 451228212000, parentCode: 451228000000, name: "三只羊乡"},
	{ code: 451228213000, parentCode: 451228000000, name: "龙湾乡"},
	{ code: 451228214000, parentCode: 451228000000, name: "菁盛乡"},
	{ code: 451228217000, parentCode: 451228000000, name: "加贵乡"},
	{ code: 451228219000, parentCode: 451228000000, name: "九渡乡"},
	{ code: 451229000000, parentCode: 451200000000, name: "大化瑶族自治县"},
	{ code: 451229100000, parentCode: 451229000000, name: "大化镇"},
	{ code: 451229101000, parentCode: 451229000000, name: "都阳镇"},
	{ code: 451229102000, parentCode: 451229000000, name: "岩滩镇"},
	{ code: 451229103000, parentCode: 451229000000, name: "北景镇"},
	{ code: 451229200000, parentCode: 451229000000, name: "共和乡"},
	{ code: 451229201000, parentCode: 451229000000, name: "贡川乡"},
	{ code: 451229202000, parentCode: 451229000000, name: "百马乡"},
	{ code: 451229203000, parentCode: 451229000000, name: "古河乡"},
	{ code: 451229204000, parentCode: 451229000000, name: "古文乡"},
	{ code: 451229205000, parentCode: 451229000000, name: "江南乡"},
	{ code: 451229206000, parentCode: 451229000000, name: "羌圩乡"},
	{ code: 451229207000, parentCode: 451229000000, name: "乙圩乡"},
	{ code: 451229210000, parentCode: 451229000000, name: "板升乡"},
	{ code: 451229211000, parentCode: 451229000000, name: "七百弄乡"},
	{ code: 451229213000, parentCode: 451229000000, name: "雅龙乡"},
	{ code: 451229214000, parentCode: 451229000000, name: "六也乡"},
	{ code: 451300000000, parentCode: 450000000000, name: "来宾市"},
	{ code: 451302000000, parentCode: 451300000000, name: "兴宾区"},
	{ code: 451302001000, parentCode: 451302000000, name: "城东街道"},
	{ code: 451302002000, parentCode: 451302000000, name: "城北街道"},
	{ code: 451302003000, parentCode: 451302000000, name: "河西街道"},
	{ code: 451302005000, parentCode: 451302000000, name: "来华街道"},
	{ code: 451302101000, parentCode: 451302000000, name: "凤凰镇"},
	{ code: 451302102000, parentCode: 451302000000, name: "良江镇"},
	{ code: 451302103000, parentCode: 451302000000, name: "小平阳镇"},
	{ code: 451302104000, parentCode: 451302000000, name: "迁江镇"},
	{ code: 451302105000, parentCode: 451302000000, name: "石陵镇"},
	{ code: 451302106000, parentCode: 451302000000, name: "平阳镇"},
	{ code: 451302107000, parentCode: 451302000000, name: "蒙村镇"},
	{ code: 451302108000, parentCode: 451302000000, name: "大湾镇"},
	{ code: 451302109000, parentCode: 451302000000, name: "桥巩镇"},
	{ code: 451302110000, parentCode: 451302000000, name: "寺山镇"},
	{ code: 451302111000, parentCode: 451302000000, name: "城厢镇"},
	{ code: 451302112000, parentCode: 451302000000, name: "三五镇"},
	{ code: 451302113000, parentCode: 451302000000, name: "陶邓镇"},
	{ code: 451302114000, parentCode: 451302000000, name: "石牙镇"},
	{ code: 451302115000, parentCode: 451302000000, name: "五山镇"},
	{ code: 451302116000, parentCode: 451302000000, name: "良塘镇"},
	{ code: 451302206000, parentCode: 451302000000, name: "七洞乡"},
	{ code: 451302213000, parentCode: 451302000000, name: "南泗乡"},
	{ code: 451302214000, parentCode: 451302000000, name: "高安乡"},
	{ code: 451302216000, parentCode: 451302000000, name: "正龙乡"},
	{ code: 451302402000, parentCode: 451302000000, name: "来华投资区"},
	{ code: 451321000000, parentCode: 451300000000, name: "忻城县"},
	{ code: 451321100000, parentCode: 451321000000, name: "城关镇"},
	{ code: 451321101000, parentCode: 451321000000, name: "大塘镇"},
	{ code: 451321102000, parentCode: 451321000000, name: "思练镇"},
	{ code: 451321103000, parentCode: 451321000000, name: "红渡镇"},
	{ code: 451321104000, parentCode: 451321000000, name: "古蓬镇"},
	{ code: 451321105000, parentCode: 451321000000, name: "果遂镇"},
	{ code: 451321201000, parentCode: 451321000000, name: "马泗乡"},
	{ code: 451321202000, parentCode: 451321000000, name: "欧洞乡"},
	{ code: 451321203000, parentCode: 451321000000, name: "安东乡"},
	{ code: 451321205000, parentCode: 451321000000, name: "新圩乡"},
	{ code: 451321206000, parentCode: 451321000000, name: "遂意乡"},
	{ code: 451321207000, parentCode: 451321000000, name: "北更乡"},
	{ code: 451322000000, parentCode: 451300000000, name: "象州县"},
	{ code: 451322100000, parentCode: 451322000000, name: "象州镇"},
	{ code: 451322101000, parentCode: 451322000000, name: "石龙镇"},
	{ code: 451322102000, parentCode: 451322000000, name: "运江镇"},
	{ code: 451322103000, parentCode: 451322000000, name: "寺村镇"},
	{ code: 451322104000, parentCode: 451322000000, name: "中平镇"},
	{ code: 451322105000, parentCode: 451322000000, name: "罗秀镇"},
	{ code: 451322106000, parentCode: 451322000000, name: "大乐镇"},
	{ code: 451322107000, parentCode: 451322000000, name: "马坪镇"},
	{ code: 451322201000, parentCode: 451322000000, name: "妙皇乡"},
	{ code: 451322202000, parentCode: 451322000000, name: "百丈乡"},
	{ code: 451322203000, parentCode: 451322000000, name: "水晶乡"},
	{ code: 451323000000, parentCode: 451300000000, name: "武宣县"},
	{ code: 451323100000, parentCode: 451323000000, name: "武宣镇"},
	{ code: 451323101000, parentCode: 451323000000, name: "桐岭镇"},
	{ code: 451323102000, parentCode: 451323000000, name: "通挽镇"},
	{ code: 451323103000, parentCode: 451323000000, name: "东乡镇"},
	{ code: 451323104000, parentCode: 451323000000, name: "三里镇"},
	{ code: 451323105000, parentCode: 451323000000, name: "二塘镇"},
	{ code: 451323106000, parentCode: 451323000000, name: "黄茆镇"},
	{ code: 451323107000, parentCode: 451323000000, name: "禄新镇"},
	{ code: 451323108000, parentCode: 451323000000, name: "思灵镇"},
	{ code: 451323204000, parentCode: 451323000000, name: "金鸡乡"},
	{ code: 451324000000, parentCode: 451300000000, name: "金秀瑶族自治县"},
	{ code: 451324100000, parentCode: 451324000000, name: "金秀镇"},
	{ code: 451324101000, parentCode: 451324000000, name: "桐木镇"},
	{ code: 451324102000, parentCode: 451324000000, name: "头排镇"},
	{ code: 451324200000, parentCode: 451324000000, name: "三角乡"},
	{ code: 451324201000, parentCode: 451324000000, name: "忠良乡"},
	{ code: 451324202000, parentCode: 451324000000, name: "罗香乡"},
	{ code: 451324203000, parentCode: 451324000000, name: "长垌乡"},
	{ code: 451324204000, parentCode: 451324000000, name: "大樟乡"},
	{ code: 451324205000, parentCode: 451324000000, name: "六巷乡"},
	{ code: 451324207000, parentCode: 451324000000, name: "三江乡"},
	{ code: 451381000000, parentCode: 451300000000, name: "合山市"},
	{ code: 451381100000, parentCode: 451381000000, name: "岭南镇"},
	{ code: 451381101000, parentCode: 451381000000, name: "北泗镇"},
	{ code: 451381102000, parentCode: 451381000000, name: "河里镇"},
	{ code: 451400000000, parentCode: 450000000000, name: "崇左市"},
	{ code: 451402000000, parentCode: 451400000000, name: "江州区"},
	{ code: 451402001000, parentCode: 451402000000, name: "太平街道"},
	{ code: 451402002000, parentCode: 451402000000, name: "江南街道"},
	{ code: 451402003000, parentCode: 451402000000, name: "石景林街道"},
	{ code: 451402101000, parentCode: 451402000000, name: "新和镇"},
	{ code: 451402102000, parentCode: 451402000000, name: "濑湍镇"},
	{ code: 451402103000, parentCode: 451402000000, name: "江州镇"},
	{ code: 451402104000, parentCode: 451402000000, name: "左州镇"},
	{ code: 451402105000, parentCode: 451402000000, name: "那隆镇"},
	{ code: 451402106000, parentCode: 451402000000, name: "驮卢镇"},
	{ code: 451402201000, parentCode: 451402000000, name: "罗白乡"},
	{ code: 451402202000, parentCode: 451402000000, name: "板利乡"},
	{ code: 451402400000, parentCode: 451402000000, name: "新和华侨农场"},
	{ code: 451402401000, parentCode: 451402000000, name: "左江华侨农场"},
	{ code: 451402402000, parentCode: 451402000000, name: "中泰产业园"},
	{ code: 451421000000, parentCode: 451400000000, name: "扶绥县"},
	{ code: 451421100000, parentCode: 451421000000, name: "新宁镇"},
	{ code: 451421101000, parentCode: 451421000000, name: "渠黎镇"},
	{ code: 451421102000, parentCode: 451421000000, name: "渠旧镇"},
	{ code: 451421103000, parentCode: 451421000000, name: "柳桥镇"},
	{ code: 451421104000, parentCode: 451421000000, name: "东门镇"},
	{ code: 451421105000, parentCode: 451421000000, name: "山圩镇"},
	{ code: 451421106000, parentCode: 451421000000, name: "中东镇"},
	{ code: 451421107000, parentCode: 451421000000, name: "东罗镇"},
	{ code: 451421201000, parentCode: 451421000000, name: "龙头乡"},
	{ code: 451421202000, parentCode: 451421000000, name: "岜盆乡"},
	{ code: 451421203000, parentCode: 451421000000, name: "昌平乡"},
	{ code: 451421401000, parentCode: 451421000000, name: "中国东盟南宁空港扶绥经济区"},
	{ code: 451421402000, parentCode: 451421000000, name: "扶绥县木业家居产业集聚区"},
	{ code: 451422000000, parentCode: 451400000000, name: "宁明县"},
	{ code: 451422100000, parentCode: 451422000000, name: "城中镇"},
	{ code: 451422101000, parentCode: 451422000000, name: "爱店镇"},
	{ code: 451422102000, parentCode: 451422000000, name: "明江镇"},
	{ code: 451422103000, parentCode: 451422000000, name: "海渊镇"},
	{ code: 451422104000, parentCode: 451422000000, name: "桐棉镇"},
	{ code: 451422105000, parentCode: 451422000000, name: "那堪镇"},
	{ code: 451422106000, parentCode: 451422000000, name: "亭亮镇"},
	{ code: 451422202000, parentCode: 451422000000, name: "寨安乡"},
	{ code: 451422203000, parentCode: 451422000000, name: "峙浪乡"},
	{ code: 451422204000, parentCode: 451422000000, name: "东安乡"},
	{ code: 451422205000, parentCode: 451422000000, name: "板棍乡"},
	{ code: 451422206000, parentCode: 451422000000, name: "北江乡"},
	{ code: 451422209000, parentCode: 451422000000, name: "那楠乡"},
	{ code: 451422401000, parentCode: 451422000000, name: "国营宁明华侨农场"},
	{ code: 451422402000, parentCode: 451422000000, name: "国营天西华侨农场"},
	{ code: 451422403000, parentCode: 451422000000, name: "国营海渊华侨农场"},
	{ code: 451423000000, parentCode: 451400000000, name: "龙州县"},
	{ code: 451423100000, parentCode: 451423000000, name: "龙州镇"},
	{ code: 451423101000, parentCode: 451423000000, name: "下冻镇"},
	{ code: 451423102000, parentCode: 451423000000, name: "水口镇"},
	{ code: 451423103000, parentCode: 451423000000, name: "金龙镇"},
	{ code: 451423104000, parentCode: 451423000000, name: "响水镇"},
	{ code: 451423201000, parentCode: 451423000000, name: "八角乡"},
	{ code: 451423202000, parentCode: 451423000000, name: "上降乡"},
	{ code: 451423203000, parentCode: 451423000000, name: "彬桥乡"},
	{ code: 451423204000, parentCode: 451423000000, name: "上龙乡"},
	{ code: 451423205000, parentCode: 451423000000, name: "武德乡"},
	{ code: 451423206000, parentCode: 451423000000, name: "逐卜乡"},
	{ code: 451423207000, parentCode: 451423000000, name: "上金乡"},
	{ code: 451424000000, parentCode: 451400000000, name: "大新县"},
	{ code: 451424100000, parentCode: 451424000000, name: "桃城镇"},
	{ code: 451424101000, parentCode: 451424000000, name: "全茗镇"},
	{ code: 451424102000, parentCode: 451424000000, name: "雷平镇"},
	{ code: 451424103000, parentCode: 451424000000, name: "硕龙镇"},
	{ code: 451424104000, parentCode: 451424000000, name: "下雷镇"},
	{ code: 451424200000, parentCode: 451424000000, name: "五山乡"},
	{ code: 451424201000, parentCode: 451424000000, name: "龙门乡"},
	{ code: 451424202000, parentCode: 451424000000, name: "昌明乡"},
	{ code: 451424203000, parentCode: 451424000000, name: "福隆乡"},
	{ code: 451424204000, parentCode: 451424000000, name: "那岭乡"},
	{ code: 451424205000, parentCode: 451424000000, name: "恩城乡"},
	{ code: 451424206000, parentCode: 451424000000, name: "榄圩乡"},
	{ code: 451424207000, parentCode: 451424000000, name: "宝圩乡"},
	{ code: 451424208000, parentCode: 451424000000, name: "堪圩乡"},
	{ code: 451424400000, parentCode: 451424000000, name: "国营桃城华侨农场"},
	{ code: 451425000000, parentCode: 451400000000, name: "天等县"},
	{ code: 451425100000, parentCode: 451425000000, name: "天等镇"},
	{ code: 451425101000, parentCode: 451425000000, name: "龙茗镇"},
	{ code: 451425102000, parentCode: 451425000000, name: "进结镇"},
	{ code: 451425103000, parentCode: 451425000000, name: "向都镇"},
	{ code: 451425104000, parentCode: 451425000000, name: "东平镇"},
	{ code: 451425105000, parentCode: 451425000000, name: "福新镇"},
	{ code: 451425200000, parentCode: 451425000000, name: "都康乡"},
	{ code: 451425201000, parentCode: 451425000000, name: "宁干乡"},
	{ code: 451425202000, parentCode: 451425000000, name: "驮堪乡"},
	{ code: 451425205000, parentCode: 451425000000, name: "进远乡"},
	{ code: 451425206000, parentCode: 451425000000, name: "上映乡"},
	{ code: 451425207000, parentCode: 451425000000, name: "把荷乡"},
	{ code: 451425208000, parentCode: 451425000000, name: "小山乡"},
	{ code: 451481000000, parentCode: 451400000000, name: "凭祥市"},
	{ code: 451481100000, parentCode: 451481000000, name: "凭祥镇"},
	{ code: 451481101000, parentCode: 451481000000, name: "友谊镇"},
	{ code: 451481102000, parentCode: 451481000000, name: "上石镇"},
	{ code: 451481103000, parentCode: 451481000000, name: "夏石镇"},
	{ code: 451481400000, parentCode: 451481000000, name: "广西凭祥综合保税区"},
]
