<template>
  <div>
    <n-tooltip :trigger="hintTrigger">
      <template #trigger>
        <span class="label" :style="style">{{ text }}</span>
      </template>
      <div>
        {{ hint }}
      </div>
    </n-tooltip>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue"

export interface TypeDefinition {
  backgroundColor: string
  textColor?: string
  weight?: number
  text: string
}

const props = withDefaults(
  defineProps<{
    value: string
    map: Map<string, TypeDefinition>
    hint?: string
  }>(),
  {
    hint: "",
  }
)

const hintTrigger = computed(() => {
  if (props.hint.length > 0) {
    return "hover"
  } else {
    return "manual"
  }
})

const text = computed(() => props.map.get(props.value)?.text ?? "")
const style = computed(() => {
  return {
    color: props.map.get(props.value)?.textColor ?? "#fff",
    "background-color": props.map.get(props.value)?.backgroundColor ?? "#fff",
    "font-weight": props.map.get(props.value)?.weight ?? 300,
  }
})
</script>
<style scoped>
.label {
  text-align: center;
  min-height: 16px;
  font-size: 12px;
  font-weight: 300;
  border-radius: 4px;
  padding: 4px 10px 4px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
