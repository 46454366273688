import { defineStore } from "pinia"
import { reactive, ref, computed } from "vue"

export const useMainStore = defineStore("main", () => {
  const state = reactive({
    initializing: false,
  })
  const initializing = computed(() => state.initializing)

  const setInitializing = (value: boolean) => {
    state.initializing = value
  }

  return {
    initializing,
    setInitializing,
  }
})
