<template>
  <div>
    <ec-button @click="open" :disabled="disabled"><slot></slot></ec-button>
    <ec-upload-input ref="upload" :multiple="true" :directory="false" @selected="onSelected" />
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue"
import { FileWrapper } from "@/components/fileSelect/FileWrapper"
import EcUploadInput from "@/components/EcUploadInput.vue"

const props = withDefaults(
  defineProps<{
    multiple?: boolean
    directory?: boolean
    disabled?: boolean
    beforeSelect?: () => Promise<void>
  }>(),
  {
    multiple: false,
    directory: false,
    disabled: false,
    text: "",
    beforeSelect: undefined,
  }
)

const emits = defineEmits<{
  (e: "selected", results: Array<FileWrapper>): void
}>()

const upload = ref<InstanceType<typeof EcUploadInput> | null>(null)

const open = () => {
  if (props.beforeSelect) {
    props
      .beforeSelect()
      .then(() => {
        upload.value?.open()
      })
      .catch(() => {})
  } else {
    upload.value?.open()
  }
}

const onSelected = (data: Array<FileWrapper>) => {
  emits("selected", data)
}
</script>
<style scoped></style>
