<template>
  <div :id="contentId" class="content" @mouseover="onMouseOverEllipsis" @mouseleave="onMouseLeaveEllipsis">
    {{ value }}
  </div>
</template>
<script lang="ts" setup>
import { computed, reactive, watch, onMounted, onUnmounted } from "vue"
import { v4 as uuid } from "uuid"

const props = withDefaults(
  defineProps<{
    value: string
    keyword?: string
    maxWidth?: number
  }>(),
  {
    value: "",
    keyword: "",
    maxWidth: 400,
  }
)
const contentId = uuid()
let timer = undefined as any
const state = reactive({
  words: [] as Array<any>,
  overflow: false,
  fullWidth: "0px",
  ellipsisHover: false,
  ellipsisHoverWaiting: false,
  show: false,
})

let fullDom = undefined as undefined | HTMLElement
const updateFullDom = () => {
  /**没有显示，且fullDom亦未创建，则无需渲染，以提高性能 */
  if (!state.show && !fullDom) {
    return
  }
  if (!fullDom) {
    fullDom = document.createElement("div")
    fullDom.innerHTML = props.value
    fullDom.setAttribute("class", "ec-ellipsis-full-container")
    fullDom.addEventListener("mouseleave", onMouseLeaveFull)
    updateFullDomStyle()
    document.body.appendChild(fullDom)
  }
  updateFullDomStyle()
}

const updateFullDomStyle = () => {
  const container = document.getElementById(contentId)
  if (!container || !fullDom) {
    return
  }
  let rect = container.getBoundingClientRect()
  let display = "block"
  if (!state.show) {
    display = "none"
  }
  let style = `display: ${display}; width: ${state.fullWidth}; top:${rect.top}px; left:${Math.max(0, rect.left - 4)}px`
  fullDom.setAttribute("style", style)
}

const onMouseOverEllipsis = () => {
  checkEllipsis()
  if (!state.overflow || state.show || state.ellipsisHoverWaiting) {
    return
  }
  state.ellipsisHover = true
  state.ellipsisHoverWaiting = true
  timer = setTimeout(() => {
    if (state.ellipsisHover) {
      state.show = true
      updateFullDom()
    }
  }, 800)
}

const onMouseLeaveEllipsis = () => {
  if (timer) {
    clearTimeout(timer)
    timer = undefined
  }
  state.ellipsisHover = false
  state.ellipsisHoverWaiting = false
}

const onMouseLeaveFull = () => {
  state.show = false
  updateFullDom()
}

const checkEllipsis = () => {
  const range = document.createRange()
  const container = document.getElementById(contentId)
  if (!container) {
    return
  }
  range.setStart(container, 0)
  range.setEnd(container, container.childNodes.length)
  let rangeWidth = range.getBoundingClientRect().width
  //忽略1像素内的差距
  const offsetWidth = rangeWidth - Math.floor(rangeWidth)
  if (offsetWidth < 1) {
    rangeWidth = Math.floor(rangeWidth)
  }
  state.overflow = rangeWidth > container.offsetWidth

  state.fullWidth = Math.min(Math.ceil(range.getBoundingClientRect().width), props.maxWidth) + 16 + "px"

  // console.log(`value=${props.value}, over=${state.overflow},  rangeWidth=${rangeWidth}, offset=${container.offsetWidth}`)
}

onMounted(() => {
  checkEllipsis()
})

onUnmounted(() => {
  if (timer) {
    clearTimeout(timer)
  }
  if (fullDom) {
    fullDom.remove()
  }
}),
  watch(
    () => props.value,
    () => {
      checkEllipsis()
    }
  )

watch(
  () => props.keyword,
  () => {
    checkEllipsis()
  }
)
</script>
<style scoped>
.hl {
  color: red;
}

.content {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-container {
  position: absolute;
  top: 0px;
  left: -4px;
  z-index: 900;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #f0f0f0;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  /* pointer-events: none; */
}
</style>
