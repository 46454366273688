<template>
  <div :class="containerClass">
    <div :class="titleClass" :style="titleStyle">
      <div class="flex-row-nowrap-start-start-start" style="margin-right: 4px">
        <div>{{ title }}</div>
        <div v-if="required" style="color: red">*</div>
        <div style="margin-left: 4px; font-weight: 400">
          <slot name="headerSuffix"></slot>
        </div>
      </div>
      <n-popover trigger="hover" v-if="hasHint">
        <template #trigger>
          <ec-icon name="common/info" :size="16" clickable />
        </template>
        <div class="form-item-hint">{{ hint }}</div>
      </n-popover>
    </div>
    <div :class="contentClass" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    title: string
    titleWidth?: number
    titlePadding?: number
    vertical?: boolean
    noPadding?: boolean
    required?: boolean
    noMinTitleWidth?: boolean
    hint?: string
    contentFlexGrow?: false //暂时定为该属性以解决部分控件需要自适应高度的问题，后续再考虑是否需要整体重构
  }>(),
  {
    vertical: false,
    titlePadding: 0,
    noPadding: false,
    required: false,
    noMinTitleWidth: false,
    contentFlexGrow: false,
  }
)

const titleStyle = computed(() => {
  let result = {
    "flex-shrink": 0,
  } as any
  if (props.titleWidth) {
    result["width"] = `${props.titleWidth}px`
  }
  if (props.vertical) {
    result["font-weight"] = "500"
  }
  if (props.titlePadding && props.titlePadding > 0) {
    result["padding-right"] = `${props.titlePadding}px`
    result["padding-left"] = `${props.titlePadding}px`
  }
  return result
})

const containerClass = computed(() => {
  return {
    "form-item-container": !props.noPadding,
    "form-item-container-no-padding": props.noPadding,
    "flex-row-nowrap-start-start-start": !props.vertical,
    "flex-col-nowrap-start-start-start": props.vertical,
  }
})

const contentClass = computed(() => {
  return {
    "form-item-content": !props.vertical,
    "form-item-content-vertical": props.vertical,
  }
})

const contentStyle = computed(() => {
  let result = {} as any
  if (props.contentFlexGrow) {
    result["flex-grow"] = 1
    if (props.vertical) {
      result["height"] = "0px"
    } else {
      result["width"] = "0px"
    }
  }
  return result
})

const titleClass = computed(() => {
  return {
    "form-item-title": !props.vertical,
    "form-item-title-vertical": props.vertical,
    "form-item-title-min-width": !props.noMinTitleWidth,
    "flex-row-nowrap-start-start-center": true,
  }
})

const hasHint = computed(() => {
  return props.hint && props.hint.length > 0
})
</script>
<style scoped>
.form-item-title {
  margin-right: 8px;
  min-height: 34px;
  box-sizing: border-box;
}

.form-item-title-vertical {
  margin-right: 8px;
  box-sizing: border-box;
}

.form-item-title-min-width {
  min-width: 80px;
}

.form-item-container {
  width: 100%;
  padding-bottom: 8px;
  min-height: 34px;
  box-sizing: border-box;
}

.form-item-container-no-padding {
  width: 100%;
  min-height: 34px;
}

.form-item-content {
  flex-grow: 1;
  width: 0px;
  min-height: 34px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
}

.form-item-content-vertical {
  width: 100%;
}

.form-item-hint {
  margin-left: 21px;
  font-size: 15px;
  color: #333;
  white-space: pre-wrap;
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: 400px;
  box-sizing: border-box;
}
</style>
