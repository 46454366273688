<template>
  <template v-for="(word, index) in state.words" :key="index">
    <span :class="index % 2 !== 0 ? 'hl' : ''">{{ word }}</span>
  </template>
</template>
<script lang="ts" setup>
import { computed, reactive, watch } from "vue"

const props = withDefaults(
  defineProps<{
    text: string
    keywords: string
  }>(),
  {
    text: "",
    keywords: "",
  }
)
//TODO: 加入最大长度设定
const state = reactive({
  words: [] as Array<string>,
})

const calculate = () => {
  if (props.keywords.length == 0) {
    state.words = [props.text]
    return
  }
  let result = [] as Array<string>
  let words = props.keywords.trim().split(/\s+/)
  let start = 0
  for (const word of words) {
    let index = props.text.indexOf(word)
    if (index == -1) {
      continue
    }
    let end = Math.max(index, 0)
    result.push(props.text.slice(start, end))
    result.push(props.text.slice(index, index + word.length))
    start = index + word.length
  }
  if (start < props.text.length) {
    result.push(props.text.slice(start))
  }
  state.words = result
}

calculate()

watch(
  () => props.text,
  () => {
    calculate()
  }
)

watch(
  () => props.keywords,
  () => {
    calculate()
  }
)
</script>
<style scoped>
.hl {
  color: red;
}
</style>
