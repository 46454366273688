<template>
  <n-breadcrumb-item v-if="state.name.length > 0" :clickable="false">{{ state.name }}</n-breadcrumb-item>
</template>
<script lang="ts" setup>
import { reactive, watch } from "vue"
import { useOrganizationStore } from "@/store/OrganizationStore"
import message from "@/util/message"

const props = defineProps<{
  organizationId: string | undefined
}>()

const organizationStore = useOrganizationStore()

const state = reactive({
  name: "",
})

const reload = async () => {
  if (!props.organizationId || props.organizationId.length <= 0) {
    state.name = ""
    return
  }
  try {
    state.name = await organizationStore.getName(props.organizationId)
  } catch (error: any) {
    message.networkError(error)
  }
}

reload()

watch(
  () => props.organizationId,
  () => {
    reload()
  }
)
</script>
<style scoped></style>
