<template>
  <div>
    <div v-show="isEmpty || loading" :style="{ width: width + 'px', height: height + 'px', color: '#bdbdbd' }" class="flex-row-nowrap-center-center-center">
      <div v-if="isEmpty">无数据</div>
      <n-spin v-if="loading" size="small" />
    </div>
    <div v-show="!isEmpty" :id="contianerId"></div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, computed, watch, reactive, nextTick } from "vue"
import { v4 as uuid } from "uuid"
import { Chart } from "@antv/g2"
import { sleep } from "@/util/timeUtil"

const props = withDefaults(
  defineProps<{
    data: Array<any>
    categoryKey?: string
    valueKey?: string
    width?: number
    height?: number
    xTitle: string
    yTitle: string
    loading?: boolean
  }>(),
  {
    categoryKey: "id",
    valueKey: "value",
    width: 300,
    height: 400,
    loading: false,
  }
)

const contianerId = uuid()
const isEmpty = computed(() => !props.data || props.data.length == 0)
const state = {
  ready: false,
  chart: {} as any,
  interval: {} as any,
}

onMounted(() => {
  const chart = new Chart({
    container: contianerId,
    width: props.width,
    height: props.height,
  })

  state.interval = chart.data(props.data).interval().tooltip(props.valueKey).encode("y", props.valueKey).encode("x", props.categoryKey).axis("x", { title: props.xTitle }).axis("y", { title: props.yTitle }).animate("enter", { type: "waveIn" })

  chart.render()
  state.chart = chart
  state.ready = true
})

watch(
  () => props.data,
  (newData, oldData) => {
    if (!state.ready) {
      return
    }
    nextTick(async () => {
      state.chart.data(newData)
      state.chart.render()
    })
  }
)
</script>
<style scoped></style>
