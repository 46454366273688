import { addresses } from "@/common/address/addresses"

export interface Address {
  code: number
  parentCode: number
  name: string
}

const parentCodeMap = new Map<number, Array<Address>>()
const codeMap = new Map<number, Address>()
const name2CodeMap = new Map<string, number>()

for (const address of addresses) {
  parentCodeMap.set(address.parentCode, parentCodeMap.get(address.parentCode) || [])
  parentCodeMap.get(address.parentCode)?.push(address)
  codeMap.set(address.code, address)
  name2CodeMap.set(address.name, address.code)
}

export function getAddressesByParentCode(parentCode: number): Array<Address> {
  return parentCodeMap.get(parentCode) || []
}

export function getAddressByCode(code: number): Address | undefined {
  return codeMap.get(code)
}

export function getCodeByName(name: string): number {
  const result = name2CodeMap.get(name)
  if (result) {
    return result
  } else {
    return 0
  }
}
