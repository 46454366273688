<template>
  <div class="form-box-container">
    <div class="flex-row-nowrap-start-start-center" :style="titleStyle">
      <div class="form-title form-box-title flex-grow-1">
        <div>
          {{ title }}
          <span v-if="error.length > 0" class="error-message" style="margin-left: 12px">
            {{ error }}
          </span>
        </div>
        <div v-if="desc.length > 0" class="form-box-desc">{{ desc }}</div>
      </div>
      <div>
        <slot name="action"></slot>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    title?: string
    titlePadding?: number
    desc?: string
    error?: string
  }>(),
  {
    title: "",
    titlePadding: 0,
    desc: "",
    error: "",
  }
)

const titleStyle = computed(() => {
  let result = {} as any
  if (props.titlePadding && props.titlePadding > 0) {
    result["padding-right"] = `${props.titlePadding}px`
    result["padding-left"] = `${props.titlePadding}px`
  }
  return result
})
</script>
<style scoped>
.form-box-title {
  margin-bottom: 4px;
}

.error-message {
  font-weight: 400;
  color: red;
  font-size: 13px;
}

.form-box-container {
  padding-top: 12px;
  padding-bottom: 24px;
}

.form-box-desc {
  color: #8993a4;
  font-size: 12px;
  font-weight: 400;
}
</style>
